import GeoSdk from "@xo-union/sdk-geo";
import get from "lodash/get";

export const requestCountryCode = async () => {
  try {
    const geoClient = new GeoSdk();
    const { body } = await geoClient.locationsByClientIP();
    const countryCode = get(body, "locations[0].countryCode");
    return countryCode;
  } catch (e) {
    return "NA";
  }
};
