import { v4 as uuidv4 } from "uuid";

export const createTestGuest = (events, weddingUuid) => {
  const guest = {
    envelopeLabel: "",
    groupId: null,
    id: uuidv4(),
    weddingId: weddingUuid,
    people: [
      {
        id: uuidv4(),
        firstName: "Test",
        lastName: "Guest",
        isLeader: true,
        invitations: [
          ...events.map(event => ({
            id: uuidv4(),
            eventId: event.id,
            rsvp: null,
          })),
        ],
      },
    ],
  };

  return { exactMatch: guest };
};
