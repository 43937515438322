import React from "react";

import { CoupleNames } from "../../Components";
import { Title } from "../../helpers";

export interface IEventTitle {
  eventName: string;
}

export function EventTitle({ eventName }: IEventTitle) {
  if (eventName.toLowerCase() === "wedding") {
    return (
      <Title data-testid="event-title">
        <CoupleNames /> {eventName}
      </Title>
    );
  }

  return <Title data-testid="event-title">{eventName}</Title>;
}
