import Axios, { AxiosResponse } from "axios";

import { ThemeStyles } from "./WeddingContext";

interface ThemeStylesResponse {
  data: {
    theme: {
      themeStyles: ThemeStyles[];
    };
  };
}

export interface IThemeStylePreviewService {
  getThemeStyle: (number) => Promise<AxiosResponse<ThemeStylesResponse>>;
}

const { REACT_APP_GUEST_SERVICES_API_URL } = process.env;

const ThemeStylePreviewService: IThemeStylePreviewService = {
  getThemeStyle(themeId): Promise<AxiosResponse<ThemeStylesResponse>> {
    return Axios.post(`${REACT_APP_GUEST_SERVICES_API_URL}/v2/graphql/public`, {
      query: `{
        theme(id: ${themeId}) {
          themeStyles
        }
      }`,
    });
  },
};

export default ThemeStylePreviewService;
