import { includes } from "lodash";
import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";

import { GuestContext, RouteProvider, PreviewContext } from "./Components/Context";
import StepContainer from "./Components/StepContainer";
import { getUrlSuffix, pushRoute } from "./Router";

export function ProtectedRoute({ component: Component, path, ...rest }) {
  const { householdLeader, partialMatches } = useContext(GuestContext);
  const { preview, previewTheme } = useContext(PreviewContext);
  let queryString = preview ? "?preview=true" : "";

  if (preview && previewTheme) {
    queryString += `&themeId=${previewTheme}`;
  }

  if (
    includes(path, "add-guests") ||
    (includes(path, "partial_match") && partialMatches && partialMatches.length) ||
    householdLeader
  ) {
    return (
      <Route
        render={props => (
          <RouteProvider>
            <StepContainer>
              <Component
                {...props}
                pushRoute={route => pushRoute(route, props.match.params, props.location.search, props.history.push)}
              />
            </StepContainer>
          </RouteProvider>
        )}
        path={path}
        {...rest}
      />
    );
  }

  return <Redirect to={`${getUrlSuffix(rest.computedMatch.params)}${queryString}`} />;
}
