import { Field as FormikField, Form, FormikProps } from "formik";
import React from "react";

import { FormField } from "../../Components";
import { IInitialValues, IOptInPref } from "./OptIn";

interface OptInFormProps extends FormikProps<IInitialValues> {
  optInPref: IOptInPref;
  setFieldValue: (type: string, value: string) => void;
  isSubmitting: boolean;
  fireTrackEvent: (params: object) => void;
  children: any;
}

export function OptInForm({ optInPref, setFieldValue, fireTrackEvent, children }: OptInFormProps) {
  return (
    <Form data-testid="rsvp-opt-in-form">
      {(optInPref === "email" || optInPref === "international") && (
        <>
          <FormikField
            name="Email"
            component={FormField}
            onFocus={() => {
              fireTrackEvent({
                selection: "add email initiated",
              });
              setFieldValue("Email", "");
            }}
          />
          {children}
        </>
      )}
    </Form>
  );
}
