import React from "react";
import { useHistory } from "react-router-dom";
import get from "lodash/get";
import Icon from "@xo-union/tk-component-icons";

import styled from "../../helpers/styled";
import { FullWidth, Hr, BoldText, Body, onKeyEnterSpace } from "../../helpers";
import { GuestContext, EventContext, RouteContext, AnalyticsContext } from "../../Components/Context";

const EventTitle = styled(BoldText)`
  font-size: 1.5rem;

  & svg {
    cursor: pointer;
    align-self: center;
    margin-right: 1.25rem;
  }
`;

const GuestName = styled(Body)`
  font-size: 1.25rem;
  margin-bottom: 1rem;
`;

const ListWrapper = styled("div")`
  margin: 2rem 1rem;
`;

const StickyTop = styled("div")`
  min-height: 1px;
  height: 1px;
  max-height: 1px;
`;

// when sticky add box shadow to header line
const StickyHeader = styled("div")`
  position: sticky;
  top: 0;
  padding-top: 1rem;
  background-color: ${({ theme }) => get(theme, "colors.background")};
  &[stuck] hr {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  }
`;

export const GuestAttendanceList = () => {
  const { guestAttendance, exactMatch } = React.useContext(GuestContext);
  const { events } = React.useContext(EventContext);
  const { pushRoute } = React.useContext(RouteContext);
  const { track } = React.useContext(AnalyticsContext);
  const { location } = useHistory();
  const householdId = get(exactMatch, "id", undefined);
  const selectedEventId = get(location, "state.eventId", undefined);

  React.useEffect(() => {
    track({
      selection: "see who is coming",
      rsvpPage: "attending list",
    });
  }, [track]);

  const getEventTitle = React.useCallback(() => {
    const event = events.find(({ id }) => id === selectedEventId);
    return event?.name;
  }, [events, selectedEventId]);

  const listGuestNames = React.useCallback(() => {
    const guestList = guestAttendance ? guestAttendance[selectedEventId] : undefined;
    let nameList: string[] = [];
    if (!guestList) return nameList;

    for (const [id, people] of Object.entries(guestList)) {
      if (!people || !people.length) continue;
      const names = people.map(({ firstName, lastName }) => `${firstName} ${lastName || ""}`);
      if (id === householdId) {
        nameList = [...names, ...nameList];
      } else {
        nameList = [...nameList, ...names];
      }
    }
    return nameList;
  }, [guestAttendance, householdId, selectedEventId]);

  // Create div refs and IntersectionObserver
  const headerTopRef = React.useRef<HTMLDivElement | null>(null);
  const headerRef = React.useRef<HTMLDivElement | null>(null);
  const observer = React.useRef<IntersectionObserver | null>(null);

  React.useEffect(() => {
    if (observer.current) observer.current.disconnect();

    // check "invisible" div, if out of view of then assume "stuck"
    observer.current = new IntersectionObserver(
      ([e]) => headerRef.current?.toggleAttribute("stuck", e.intersectionRatio < 1),
      { threshold: [1] }
    );
    if (headerTopRef.current) observer.current.observe(headerTopRef.current);

    // cleanup
    return () => observer.current?.disconnect();
  });

  const eventName = getEventTitle();
  return (
    <FullWidth>
      <StickyTop
        ref={node => {
          if (node) headerTopRef.current = node;
        }}
      />
      <StickyHeader
        ref={node => {
          if (node) headerRef.current = node;
        }}
      >
        <EventTitle>
          <Icon
            name="caret_left"
            data-testid="Icon_Back"
            size="md"
            tabIndex={0}
            onClick={() => pushRoute("/confirmation", {})}
            onKeyDown={e => onKeyEnterSpace(e, () => pushRoute("/confirmation", {}))}
          />
          {eventName} Attendees
        </EventTitle>
        <Hr />
      </StickyHeader>
      <ListWrapper>
        {listGuestNames().map((name, i) => (
          <GuestName key={`${name} ${i}`}>{name}</GuestName>
        ))}
      </ListWrapper>
    </FullWidth>
  );
};
