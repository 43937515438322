import React from "react";

import { systemError } from "../helpers";
import { WarningToast } from "./WarningToast";

interface ApiErrorProps {
  testId: string;
}

export function ApiError({ testId }: ApiErrorProps) {
  return <WarningToast testId={testId}>{systemError}</WarningToast>;
}
