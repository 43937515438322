import React from "react";
import get from "lodash/get";
import { Global } from "@emotion/core";
import { compose } from "@xo-union/react-css-modules";
import { Textarea } from "@xo-union/tk-component-fields";
import styled from "../helpers/styled";

interface ITextBoxProps {
  onFocus: () => void;
  onChange: (string) => void;
  name: string;
  label: string | false;
  value: string;
  maxLength?: number;
  placeholder?: string;
  subText?: string;
}

const Wrapper = styled("div")<{}>(({ theme }) => ({
  textarea: {
    ...get(theme, "fonts.s6"),
    backgroundColor: `${get(theme, "colors.background")} !important`,
    borderColor: `${get(theme, "colors.primary")} !important`,
    "&:focus": {
      borderColor: `${get(theme, "colors.primary")} !important`,
    },
  },
  "textarea + div[class*='length-indicator']": {
    ...get(theme, "fonts.tiny"),
    color: `${get(theme, "colors.primary")} !important`,
  },
}));

const textAreaClasses = compose({
  container: "textarea-container",
  input: "textarea-input",
});

const ThemedTextBoxField = (props: ITextBoxProps) => {
  const { maxLength, onFocus, onChange, name, label, value, placeholder, subText } = props;
  return (
    <Wrapper>
      <Global
        styles={{
          ".textarea-container": {
            height: "140px",
            marginBottom: "32px",
          },
          ".textarea-input": {
            height: "100%",
          },
        }}
      />
      <Textarea
        classes={textAreaClasses}
        name={name}
        placeholder={placeholder}
        subText={subText}
        data-testid={`TextArea-${name}`}
        maxLength={maxLength}
        label={label}
        value={value}
        onFocus={() => onFocus()}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value)}
      />
    </Wrapper>
  );
};

export default ThemedTextBoxField;
