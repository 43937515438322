import Axios, { AxiosResponse } from "axios";

export interface ScheduleBody {
  member_id: string;
  sequence_id: string;
  guest_ids: string[];
  preference: string;
  wedding_id: string;
}

export interface PreferenceResponse {
  emailOptIn: boolean;
  smsOptIn: boolean;
  smsGlobalAllowed: boolean;
}

export interface IGuestMessageService {
  scheduleGuestMessage: (body: ScheduleBody) => Promise<AxiosResponse>;
  fetchExistingPreferences: (weddingID: string, guestID: string) => Promise<AxiosResponse>;
}

const { REACT_APP_GUEST_MESSAGING_URL } = process.env;

export const GuestMessagingService: IGuestMessageService = {
  scheduleGuestMessage(body) {
    return Axios.post(`${REACT_APP_GUEST_MESSAGING_URL}/v1/schedule_guest_message`, body);
  },
  fetchExistingPreferences(weddingID, guestID) {
    return Axios.get(`${REACT_APP_GUEST_MESSAGING_URL}/v1/preferences/${weddingID}/${guestID}`);
  },
};
