import React, { useContext } from "react";

import { AnalyticsContext, GuestContext } from "../../Components/Context";
import { GuestName } from "../../helpers";
import styled from "../../helpers/styled";
import { RsvpButton } from "./RsvpButton";

type IRsvpType = "accept" | "reject" | string;

export interface IEventRsvpProps {
  eventId: string;
  guestId: string;
  value: IRsvpType;
  onChange: (value: boolean, guestId: string, eventId: string) => void;
  isLast: boolean;
}

const ButtonGroup = styled("div")`
  display: flex;

  button:first-of-type {
    margin-left: 8px;
    margin-right: 12px;
    @media (max-width: 480px) {
      margin-right: 8px;
    }
  }
`;

const Wrapper = styled<any>("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ isLast }) => (isLast ? "0" : "24px")};
`;

export function EventRsvp({ value, onChange, guestId, eventId, isLast }: IEventRsvpProps) {
  const { getGuestById } = useContext(GuestContext);
  const { track } = useContext(AnalyticsContext);
  const guest = getGuestById(guestId);

  if (guest) {
    return (
      <Wrapper isLast={isLast} data-testid={`rsvp-${guest.firstName}-${guest.lastName}`}>
        <GuestName>
          {guest.firstName} {guest.lastName}
        </GuestName>
        <ButtonGroup>
          <RsvpButton
            onClick={() => {
              onChange(true, guestId, eventId);
              track({ selection: "attend", rsvpPage: "event response" });
            }}
            selection={value}
            type="accept"
          />
          <RsvpButton
            onClick={() => {
              onChange(false, guestId, eventId);
              track({ selection: "decline", rsvpPage: "event response" });
            }}
            selection={value}
            type="reject"
          />
        </ButtonGroup>
      </Wrapper>
    );
  }

  return null;
}
