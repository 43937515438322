import React from "react";

import { Title } from "../../helpers";

interface OptInHeadingProps {
  weddingName: string;
}

export function OptInHeading({ weddingName }: OptInHeadingProps) {
  return <Title>Last step! Send your RSVP to {weddingName}</Title>;
}
