import React, { useContext, useState } from "react";
import striptags from "striptags";

import { AnalyticsContext } from "../../Components/Context";
import { IconWrapper, Link, Body, EventIcon } from "../../helpers";

export interface EventNoteProps {
  note?: string;
}

const NOTE_LENGTH = 35;

export function EventNote({ note }: EventNoteProps) {
  const [showFullNote, setShowFullNote] = useState(false);
  const { track } = useContext(AnalyticsContext);

  const icon = <EventIcon name="notes" size="sm" />;

  if (note) {
    const notes = striptags(note).replace(/&nbsp;/gi, " ");

    if (notes.length >= NOTE_LENGTH) {
      const firstPart = notes.slice(0, NOTE_LENGTH);

      return (
        <Body data-testid="event-note">
          <IconWrapper>{icon}</IconWrapper>
          {showFullNote ? (
            notes
          ) : (
            <span>
              {firstPart}...
              <Link
                data-testid="event-full-note"
                onClick={() => {
                  setShowFullNote(true);
                  track({ selection: "view event details", rsvpPage: "event response" });
                }}
              >
                Read More
              </Link>
            </span>
          )}
        </Body>
      );
    }

    return (
      <Body data-testid="event-note">
        <IconWrapper>{icon}</IconWrapper>
        {notes}
      </Body>
    );
  }

  return null;
}
