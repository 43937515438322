import { ITheme } from "./styled";

const theme: ITheme = {
  white: "#ffffff",
  "gray-8": "#e5e5e5",
  "gray-7": "#cccccc",
  "gray-5": "#999999",
  "gray-4": "#787878",
  black: "#1f1f1f",
  "knot-blue-darker-20": "#4ab4e5",
  colors: {},
  config: {},
  components: {},
  fonts: {},
  images: {},
  responsiveImages: {
    top: {},
    bottom: {},
    background: {},
  },
  separator: {},
};

export default theme;
