import { css } from "@emotion/core";
import isPropValid from "@emotion/is-prop-valid";
import Icon from "@xo-union/tk-component-icons";
import get from "lodash-es/get";
import { varCtaDefault, varWhite, varMidnight } from "@xo-union/tk-ui-colors/lib/variables.css";
import styled from "./styled";

export const FlexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoStyleButton = styled("button")`
  border: 0;
  cursor: pointer;
  background-color: transparent;
`;

export const FlexDiv = styled("div")`
  display: flex;
`;

export const Heading = styled("h2")`
  font-size: 20px;
  color: ${({ theme }) => theme.black};
`;

export const Title = styled("h2")(
  `
  font-size: 2rem;
  padding-bottom: 28px;
  @media (max-width: 768px) {
    font-size: 28px;
  }
`,
  ({ theme }) => ({
    ...get(theme, "fonts.s3", {}),
    ...get(theme, "components.SiteTitle", {}),
  })
);

export const ItemHeading = styled("h3")`
  color: ${({ theme }) => get(theme, ["fonts", "s4", "color"]) || "black"};
  font-size: ${({ theme }) => get(theme, ["fonts", "s4", "fontSize"]) || "18px"};
  font-weight: ${({ theme }) => get(theme, ["fonts", "s4", "fontWeight"]) || "normal"};
  font-family: ${({ theme }) => get(theme, ["fonts", "s4", "fontFamily"]) || ""};
`;

export const Body = styled("div")`
  color: ${({ theme }) => get(theme, ["fonts", "s6", "color"]) || get(theme, ["gray-4"])};
  font-size: ${({ theme }) => get(theme, ["fonts", "s6", "fontSize"]) || "14px"};
  font-weight: ${({ theme }) => get(theme, ["fonts", "s6", "fontWeight"]) || "normal"};
  font-family: ${({ theme }) => get(theme, ["fonts", "s6", "fontFamily"]) || ""};
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
`;

export const BoldText = styled("div")`
  color: ${({ theme }) => get(theme, ["fonts", "s6", "color"]) || get(theme, ["gray-4"])};
  font-size: ${({ theme }) => get(theme, ["fonts", "s6", "fontSize"]) || "14px"};
  font-weight: bold;
  font-family: ${({ theme }) => get(theme, ["fonts", "s6", "fontFamily"]) || ""};
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
`;

export const BoldInlineText = styled("span")`
  color: ${({ theme }) => get(theme, ["fonts", "s6", "color"]) || get(theme, ["gray-4"])};
  font-size: ${({ theme }) => get(theme, ["fonts", "s6", "fontSize"]) || "14px"};
  font-weight: bold;
  font-family: ${({ theme }) => get(theme, ["fonts", "s6", "fontFamily"]) || ""};
`;

export const Text = styled("div")`
  font-size: 14px;
  color: ${props => props.theme["gray-4"]};
  display: flex;
  align-items: end;
  margin-bottom: 8px;
`;

export const TextDisplayBlock = styled("div")`
  font-size: 14px;
  color: ${({ theme }) => get(theme, ["fonts", "s6", "color"]) || get(theme, ["gray-4"])};
  align-items: end;
  margin-bottom: 8px;
`;

export const IconWrapper = styled("span")`
  margin-right: 9px;
`;

export const EventIcon = styled(Icon)`
  position: relative;
  top: 2px;
  vertical-align: baseline;
`;

export const GuestName = styled(Body)`
  font-size: 20px;
  margin-bottom: 0px;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

export interface IRoundButtonProps {
  isSelected?: boolean;
  name: string;
  onClick?: () => void;
}

export interface IAcceptRejectButtonProps {
  selected: boolean;
  noResponse: boolean;
  onClick?: () => void;
}

export interface IThemeWrapperProps {
  hasCovidBanner?: boolean;
  hasPreviewBanner?: boolean;
}

export interface IRsvpIconProp {
  isSelected: boolean;
  onClick?: () => void;
  small: boolean;
  colors?: {
    background: string;
    hover: string;
  };
}

export const RoundButton = styled("button", {
  shouldForwardProp: propName => isPropValid(propName),
})<IRsvpIconProp>`
  width: ${({ small }) => (small ? "22px" : "32px")};
  height: ${({ small }) => (small ? "22px" : "32px")};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;

  .icon {
    display: inline-block;
    fill: currentColor;
    stroke: currentColor;
    stroke-width: 0;
  }

  .icon-has-no-size {
    width: 1.2em;
    height: 1.2em;
  }

  ${({ small }) => {
    if (small) {
      return `.icon-has-no-size {
        width: 10px;
        height: 10px;
      }`;
    }
  }}
`;

export const Link = styled("a")(({ theme }) => ({
  color: theme["knot-blue-darker-20"],
  marginLeft: "5px",
  cursor: "pointer",
  borderBottom: "1px solid currentColor",
  fontSize: "0.75rem",
  ...get(theme, "fonts.tiny", {}),
  "&:hover": {
    color: theme["knot-blue-darker-20"],
    ...get(theme, "fonts.tiny", {}),
  },
}));

export const InlineLink = styled("a")(({ theme }) => ({
  color: "inherit",
  cursor: "pointer",
  borderBottom: "1px solid currentColor",
  "&:hover": {
    opacity: ".9",
  },
}));

export const H5 = styled("h5")`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.black};
  letter-spacing: 0.6px;
  font-family: union-sans-serif, Georgia, Times New Roman, Times, serif;
`;

export const Hr = styled("hr")`
  margin-top: 16px;
  margin-bottom: 40px;
  border: 0.5px solid ${({ theme }) => get(theme, "colors.primary", theme["gray-8"])};
`;

export const FlexSpaceBtw = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const FullWidth = styled("div")`
  width: 100%;
`;

export const BaseButton = styled("button")`
  font-size: 1.125rem;
  line-height: 2.75rem;
  background-color: ${varCtaDefault};
  border-color: ${varCtaDefault};
  color: ${varWhite};
  min-width: 180px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  border-radius: 2px;
  cursor: pointer;
  padding-bottom: 0;
  padding-top: 0.125rem;
  text-align: center;
  display: block !important;
  width: 100% !important;
  transition: all 0.1s ease-out;
  transition-property: background-color, color, border-color;
`;

export const ThemeWrapper = styled("div")<IThemeWrapperProps>(({ theme, hasCovidBanner, hasPreviewBanner }) => {
  let heightOffset = 68;
  if (hasCovidBanner) heightOffset += 148;
  if (hasPreviewBanner) heightOffset += 53;

  return {
    width: "100vw",
    height: `calc(100vh - ${heightOffset}px)`,
    overflow: "auto",
    backgroundSize: "cover",
    backgroundImage: `url(${get(theme, ["images", "background"])})`,
    backgroundColor: `${get(theme, ["colors", "background"])}`,
    ...get(theme, ["components", "BackgroundImage"], {}),
  };
});

export const Banner = styled("div")`
  background-color: ${({ theme }) => get(theme, ["colors", "primary"]) || varWhite};
  min-height: 118px;
  width: 100%;
`;

export const BannerText = styled("div")`
  color: ${({ theme }) => get(theme, ["colors", "background"]) || varMidnight};
  font-size: ${({ theme }) => get(theme, ["fonts", "s6", "fontSize"]) || "14px"};
  font-weight: ${({ theme }) => get(theme, ["fonts", "s6", "fontWeight"]) || "bold"};
  font-family: ${({ theme }) => get(theme, ["fonts", "s6", "fontFamily"])};
  max-width: 740px;
  padding: 18px 40px 18px 40px;
  margin: auto;
  text-align: center;
`;
