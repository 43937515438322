import React, { useContext, useEffect } from "react";
import get from "lodash/get";

import styled from "../../helpers/styled";
import { GuestConsumer } from "../../Components/Context";
import { AnalyticsContext, EventContext } from "../../Components/Context";
import { findUnnamedGuests, FullWidth, IHistoryProps } from "../../helpers";
import ThemedButton from "../../ThemedButton";
import { EditNameItem } from "./EditNameItem";
import { EditNameTitle } from "./EditNameTitle";

const Wrapper = styled(FullWidth)`
  margin-bottom: 30px;
`;

export function EditName({ pushRoute }: IHistoryProps) {
  const { eventBody } = useContext(EventContext);
  const { track } = useContext(AnalyticsContext);

  useEffect(() => {
    track({
      rsvpPage: "edit guest",
      hasPlusOne: true,
    });
  });

  return (
    <div>
      <GuestConsumer>
        {({ people, updateGuestName, areNamesEmpty, householdLeader }) => (
          <>
            <EditNameTitle leaderName={householdLeader ? householdLeader.firstName : ""} />
            <Wrapper>
              {people.map(guest => {
                const canEdit = findUnnamedGuests(guest) || guest.edited;
                if (!guest.isLeader && canEdit) {
                  return <EditNameItem guest={guest} key={guest.id} track={track} updateGuestName={updateGuestName} />;
                }
                return null;
              })}
            </Wrapper>
            <ThemedButton
              text="Continue"
              disabled={areNamesEmpty()}
              onClick={() => {
                !get(eventBody, "isPrivateRsvp") ? pushRoute("add-guests") : pushRoute("events/0");
                track({
                  rsvpPage: "edit guest",
                  selection: "save guest name",
                });
              }}
            />
          </>
        )}
      </GuestConsumer>
    </div>
  );
}
