import axios from "axios";
import honeybadger from "honeybadger-js";
import React, { useContext, useEffect, useReducer } from "react";

import { AnalyticsContext, WeddingContext, GuestContext, PreviewContext } from "../../Components/Context";
import PreviewBanner from "../../Components/PreviewBanner";
import { FullWidth, Hr as ImportedHr, IHistoryProps } from "../../helpers";
import styled from "../../helpers/styled";
import { ConfirmationButtons } from "./ConfirmationButtons";
import { ConfirmationEvents } from "./ConfirmationEvents";
import { ConfirmationHeading } from "./ConfirmationHeading";
import { RsvpResponseTitle } from "./RsvpResponseTitle";

type values = "home" | "gift" | "accommodations";

export interface RegistryAPI {
  id: string;
  coupleUuid: string;
  memberUuid: string;
  mergedCoupleId: null;
  mergedCoupleUuid: null;
  firstName: string;
  lastName: string;
  email: string;
  fianceFirstName: string;
  fianceLastName: string;
  fianceEmail: null;
  eventDate: string;
  eventTypeId: number;
  city: string;
  state: string;
  zip: string;
  country: string;
  isDeleted: boolean;
  isPrivate: boolean;
  isHiddenSearchEngines: boolean;
  isActive: boolean;
  isManualOverrided: boolean;
  isHiddenProducts: boolean;
  isHiddenProductsInWebsite: boolean;
  isHiddenRegistriesInWebsite: boolean;
  shortUrl: null;
  stripeAccountStatus: null;
  isCashRegistryActive: boolean;
  registries: Registry[];
  charity: null;
  wws: Wws;
}

export interface Registry {
  id: string;
  registryUuid: string;
  registryType: number;
  manualRegistryName?: string;
  manualRegistryUrl?: string;
  hiddenCoupleSearch: boolean;
  hiddenWws: boolean;
  isDeleted: boolean;
  retailer: Retailer;
}

export interface Retailer {
  id: number;
  retailerName: string;
  description: string;
  eventType: number;
  website: null | string;
  domain: null | string;
  additionalInfoUrl: null | string;
  storeInfoPageUrl: null | string;
  fullLogoImageUrl: string;
  smallLogoImageUrl: string;
  privacyPolicyUrl: string;
  registryCreateLogoImageUrl: null | string;
  returnAndExchangePolicy: null | string;
  oneColSortOrder: number;
  twoColSortOrder: null;
  threeColSortOrder: null;
  fourColSortOrder: number;
  isPartner: boolean;
  isDeleted: boolean;
  tier: number | null;
  benefits: null | string;
  slug: string;
}

export interface Wws {
  id: string;
  wwsUuid: string;
  affiliateId: number;
  affiliateWebsiteCode: string;
  websiteUrl: string;
  isDeleted: boolean;
  isHiddenWebsite: boolean;
}

export type RsvpNavOptions = Array<values>;

const Hr = styled(ImportedHr)`
  margin: 10px 0;
`;

const FullWidthWithMargin = styled(FullWidth)`
  margin-bottom: 80px;
`;

function reducer(state, payload) {
  return [...state, payload];
}

export function Confirmation({ pushRoute, location }: IHistoryProps) {
  const { wedding, weddingSlug } = useContext(WeddingContext);
  const { getGuestAttendanceList } = useContext(GuestContext);
  const { preview } = useContext(PreviewContext);

  const { track } = useContext(AnalyticsContext);
  const [rsvpNavOptions, setRsvpNavOptions] = useReducer(reducer, ["home"]);

  const ConfirmationContainer = preview ? FullWidthWithMargin : FullWidth;
  const weddingName = wedding && wedding.wedding_name ? `${wedding.wedding_name}` : "";
  const rootWebUrl = `${process.env.REACT_APP_WWS_URL}/${weddingSlug}`;

  React.useEffect(() => {
    async function fetchGuestAttendance() {
      await getGuestAttendanceList();
    }
    fetchGuestAttendance();
  }, [getGuestAttendanceList]);

  useEffect(() => {
    if (wedding && wedding.accommodations && wedding.accommodations.length) {
      setRsvpNavOptions("accommodations");
    }

    const userUuid = wedding && wedding.user_uuid ? wedding.user_uuid : "";
    axios
      .get<RegistryAPI>(`${process.env.REACT_APP_GUEST_SERVICES_URL}/us/api/registries`, {
        params: {
          member_id: userUuid,
        },
      })
      .then(({ data }) => {
        if (data.registries && data.registries.length) {
          setRsvpNavOptions("gift");
        }
        track({
          rsvpPage: "rsvp confirmation",
          rsvpNavOptions,
        });
      })
      .catch(error => {
        honeybadger.notify(error, {
          name: "Registry API",
          context: {
            apiResponse: error,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (location.state && location.state.completedRsvp) {
    return (
      <ConfirmationContainer>
        <ConfirmationHeading weddingName={weddingName} completed={true} />

        <RsvpResponseTitle pushRoute={pushRoute} rsvpNavOptions={rsvpNavOptions} track={track} />
        <Hr />
        <ConfirmationEvents rsvpNavOptions={rsvpNavOptions} />

        <ConfirmationButtons rsvpNavOptions={rsvpNavOptions} rootWebUrl={rootWebUrl} />
        {preview ? <PreviewBanner restart pushRoute={pushRoute} /> : null}
      </ConfirmationContainer>
    );
  }

  return (
    <ConfirmationContainer>
      <ConfirmationHeading weddingName={weddingName} completed={false} />
      <ConfirmationButtons rsvpNavOptions={rsvpNavOptions} rootWebUrl={rootWebUrl} />

      <RsvpResponseTitle pushRoute={pushRoute} rsvpNavOptions={rsvpNavOptions} track={track} />
      <Hr />

      <ConfirmationEvents rsvpNavOptions={rsvpNavOptions} />
      {preview ? <PreviewBanner restart pushRoute={pushRoute} /> : null}
    </ConfirmationContainer>
  );
}
