import React from "react";
import { Redirect } from "react-router-dom";

import { EventContext, GuestContext, RouteContext, AnalyticsContext, Question } from "../../Components/Context";
import { FullWidth, Title } from "../../helpers";
import { IGuestResponseProps } from "../GuestResponse/GuestResponse";
import Select from "./Select";
import ShortAnswer from "./ShortAnswer";

const OPTION_TYPE = "Option";
const TEXT_TYPE = "Text";

interface ConfirmedProps {
  question: Question;
}

export const GeneralQuestionPage = (props: IGuestResponseProps) => {
  const { questionIndex, nextRoute } = React.useContext(RouteContext);

  const { eventBody } = React.useContext(EventContext);
  const { guestWeddingQuestions } = eventBody || {};

  const redirectToNext = () => {
    return <Redirect to={nextRoute} />;
  };

  if ((!questionIndex && questionIndex !== 0) || !guestWeddingQuestions) {
    return redirectToNext();
  }
  const question = guestWeddingQuestions[questionIndex];

  if (!question) {
    return redirectToNext();
  }

  return <QuestionConfirmedPage question={question} />;
};

const QuestionConfirmedPage = (props: ConfirmedProps) => {
  const { question } = props;

  const { nextRoute } = React.useContext(RouteContext);
  const { track } = React.useContext(AnalyticsContext);
  const { exactMatch: household, updateHouseholdAnswers } = React.useContext(GuestContext);
  const { answers: hAnswers } = household || {};
  const { getAllQuestions } = React.useContext(EventContext);

  const questionIds = React.useMemo(() => getAllQuestions()?.keys(), [getAllQuestions]);
  const nthQuestion = React.useMemo(() => questionIds && Array.from(questionIds).indexOf(question.id) + 1, [
    question.id,
    questionIds,
  ]);

  React.useEffect(() => {
    if (question) {
      track({
        // the nth Question of total questions
        rsvpPage: `Custom Question ${nthQuestion}`,
        questionTitle: question.text,
        answerType: question.answerType,
        questionType: question.type,
      });
    }
  }, [nthQuestion, question, track]);

  const findPreviousAnswer = () => {
    const previousAnswer = hAnswers?.find(answer => answer.questionId === question.id);
    return previousAnswer;
  };

  const questionText = question.text;
  const renderQuestionComponent = () => {
    switch (question.answerType) {
      case OPTION_TYPE:
        return (
          <Select
            keyPrefix={`${question.id}`}
            question={question}
            findPreviousAnswer={() => {
              const { optionId } = findPreviousAnswer() || {};
              return optionId || undefined;
            }}
            updateAnswer={updateHouseholdAnswers}
          />
        );
      case TEXT_TYPE:
        return (
          <ShortAnswer
            question={question}
            findPreviousAnswer={() => {
              const { text } = findPreviousAnswer() || {};
              return text || "";
            }}
            updateAnswer={updateHouseholdAnswers}
          />
        );
      default:
        // Move on to the next if unexpected reasons
        return <Redirect to={nextRoute} />;
    }
  };

  return (
    <FullWidth>
      <Title>{questionText}</Title>
      {renderQuestionComponent()}
    </FullWidth>
  );
};

export default GeneralQuestionPage;
