import { Formik, FormikProps } from "formik";
import React from "react";
import get from "lodash-es/get";

import { InvitationContext, IUpdateGuestName, Person } from "../../Components/Context";
import { FlexDiv, NoStyleButton, Text, GuestName, Link } from "../../helpers";
import styled from "../../helpers/styled";
import { formSchema, IFormValues, initialValues } from "../GuestSearch/FormDefaults";
import GuestSearchForm from "../GuestSearch/GuestSearchForm";

export interface IEditNameItem {
  guest: Person;
  updateGuestName: IUpdateGuestName;
  track: (attributes: any, message?: string | undefined) => void;
}

export interface IEditNameState {
  isEditing: boolean;
  isDeleted: any;
}

const Wrapper = styled("div")`
  flex: 1;
  display: flex;
`;

const UndoAnchor = styled(Link)`
  position: relative;
  top: 3px;
`;

const UndoText = styled(Text)(({ theme }) => ({
  marginTop: "4px",
  marginBottom: "0px",
  ...get(theme, "fonts.tiny", {}),
}));

const NotAttendingText = styled("span")`
  margin-top: 2px;
`;

const MarginBottom = styled(FlexDiv)`
  margin-bottom: 20px;
`;
// TODO: (GDS) Test if it still works
export class EditNameItem extends React.Component<IEditNameItem, IEditNameState> {
  state = {
    isEditing: false,
    isDeleted: this.context.invitations[`${this.props.guest.id}_deleted`] || false,
  };

  static contextType = InvitationContext;
  context!: React.ContextType<typeof InvitationContext>;

  editGuestName = () => {
    const { track } = this.props;

    this.setState({ isEditing: true });
    track({
      rsvpPage: "edit guest",
      selection: "update guest name",
    });
  };

  updateName = (values: IFormValues) => {
    if (values["Full Name"]) {
      this.props.updateGuestName(this.props.guest.id, values["Full Name"]);
      this.setState({ isEditing: false });
    }
  };

  deleteUser = () => {
    const { track } = this.props;

    this.context.updateAllRsvpForGuest(this.props.guest.id, false);
    this.setState({ isDeleted: true });
    track({
      rsvpPage: "edit guest",
      selection: "decline",
    });
  };

  undoDelete = () => {
    this.context.updateAllRsvpForGuest(this.props.guest.id, "");
    this.setState({ isDeleted: false });
  };

  render() {
    const { guest } = this.props;
    const { isEditing, isDeleted } = this.state;

    if (isEditing) {
      return (
        <Formik initialValues={initialValues} onSubmit={this.updateName} validationSchema={formSchema}>
          {(formProps: FormikProps<IFormValues>) => (
            <GuestSearchForm {...formProps} buttonText="Save" noButton={true} onBlur={this.updateName} />
          )}
        </Formik>
      );
    }

    let delButton = (
      <NoStyleButton data-testid="event-delete" onClick={this.deleteUser}>
        <Link>Not Attending</Link>
      </NoStyleButton>
    );

    if (isDeleted) {
      delButton = (
        <UndoText>
          <NotAttendingText>Not Attending | </NotAttendingText>
          <UndoAnchor onClick={this.undoDelete}>Undo</UndoAnchor>
        </UndoText>
      );
    }

    return (
      <MarginBottom>
        <Wrapper>
          <GuestName>
            {guest.firstName} {guest.lastName}
          </GuestName>
          <NoStyleButton onClick={this.editGuestName}>
            <Link>Edit</Link>
          </NoStyleButton>
        </Wrapper>
        {delButton}
      </MarginBottom>
    );
  }
}
