import { Field } from "@xo-union/tk-component-fields";
import { FieldProps } from "formik";
import get from "lodash/get";
import React from "react";

import styled from "../helpers/styled";

const Wrapper = styled("div")<{}>(
  {
    paddingBottom: "15px",
    marginBottom: "15px",
  },
  ({ theme }) => ({
    ...get(theme, "fonts.s6", {}),
    // override input field styling with theme variables
    "& input": {
      backgroundColor: `${get(theme, "colors.background")} !important`,
      color: get(theme, "fonts.s6.color"),
      fontFamily: get(theme, "fonts.s6.fontFamily"),
      fontWeight: get(theme, "fonts.s6.fontWeight"),
      letterSpacing: get(theme, "fonts.s6.letterSpacing"),
    },
    // to preserve error state only apply to the neutral state class
    "& input[class*='is-neutral']": {
      borderColor: get(theme, "colors.primary"),
    },
    // style the message subtext
    "& input[class*='is-invalid'] ~ div[class*='sub-text']": { ...get(theme, "fonts.tiny") },
    // style the placeholder label
    "& input[class*='input-with-animated-label'] ~ label[class*='animated-label']": {
      fontFamily: get(theme, "fonts.tiny.fontFamily"),
      color: get(theme, "colors.primary") || "#9699A0",
    },
  })
);

export interface IFormField extends FieldProps {
  inputRef: Function;
  subText?: string;
}

export function FormField(props: IFormField) {
  const {
    field,
    inputRef,
    form: { touched, errors },
    subText,
    ...rest
  } = props;

  let text: any = subText;

  if (touched[field.name] && errors[field.name]) {
    text = errors[field.name];
  }

  return (
    <Wrapper>
      <Field
        inputRef={inputRef}
        {...field}
        {...rest}
        state={touched[field.name] && errors[field.name] ? "invalid" : "neutral"}
        subText={text}
      />
    </Wrapper>
  );
}

export const ThemedField = props => (
  <Wrapper>
    <Field {...props} />
  </Wrapper>
);
