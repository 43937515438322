import axios, { AxiosResponse } from "axios";

import { IGuestApi, Match, MatchedSubmitBody } from "./IGuestContext";

export interface IGuestSearchService {
  searchForGuest(
    ...args: [{ weddingUuid: string; full_name?: string; id?: string }]
  ): Promise<AxiosResponse<IGuestApi>>;
  updateGuestResponse<T>(
    ...args: [{ body: T; weddingUuid: string; householdId: string; memberUuid: string }]
  ): Promise<AxiosResponse<Match>>;
  createNewGuest(
    ...args: [{ body: MatchedSubmitBody; weddingUuid: string; memberUuid: string }]
  ): Promise<AxiosResponse<Match>>;
}

const { REACT_APP_GDS_URL, REACT_APP_GDS_API_KEY } = process.env;

export const GuestSearchService: IGuestSearchService = {
  searchForGuest({ weddingUuid, full_name, id }): Promise<AxiosResponse<IGuestApi>> {
    let params;

    if (full_name) {
      params = {
        full_name,
      };
    } else if (id) {
      params = {
        id,
      };
    }
    return axios.get(`${REACT_APP_GDS_URL}/v1/weddings/${weddingUuid}/guests`, {
      params,
    });
  },
  updateGuestResponse({ body, weddingUuid, householdId, memberUuid }): Promise<AxiosResponse<Match>> {
    return axios.patch(`${REACT_APP_GDS_URL}/v1/weddings/${weddingUuid}/households/${householdId}`, body, {
      headers: {
        "X-MEMBER-UUID": memberUuid,
        "X-API-KEY": REACT_APP_GDS_API_KEY,
      },
    });
  },
  createNewGuest({ body, weddingUuid, memberUuid }): Promise<AxiosResponse<Match>> {
    return axios.post(`${REACT_APP_GDS_URL}/v1/weddings/${weddingUuid}/households`, body, {
      headers: {
        "X-MEMBER-UUID": memberUuid,
        "X-API-KEY": REACT_APP_GDS_API_KEY,
      },
    });
  },
};
