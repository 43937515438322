import UnionIcon from "@xo-union/tk-component-icons";
import React, { useContext } from "react";
import get from "lodash/get";

import { AnalyticsContext, WeddingContext } from "../../Components/Context";
import styled from "../../helpers/styled";
import { Link } from "../../helpers";
import { RsvpNavOptions } from "./Confirmation";

const { REACT_APP_GUEST_MESSAGING_URL } = process.env;

const A = styled(Link)(({ theme }) => ({
  display: "flex",
  fontSize: "1rem",
  ...get(theme, "fonts.s6"),
  "&:hover": {
    ...get(theme, "fonts.s6"),
  },
}));

interface AddToCalendarProps {
  eventId: string;
  rsvpNavOptions: RsvpNavOptions;
}

const Icon = styled(UnionIcon)`
  margin-right: 5px;
`;

export function AddToCalendar({ eventId, rsvpNavOptions }: AddToCalendarProps) {
  const { wedding } = useContext(WeddingContext);
  const { track } = useContext(AnalyticsContext);

  return (
    <A
      onClick={() => track({ selection: "add to calendar", rsvpPage: "rsvp confirmation", rsvpNavOptions })}
      href={`${REACT_APP_GUEST_MESSAGING_URL}/v1/event_ics/${wedding && wedding.user_uuid}/${eventId}`}
      target="_blank"
    >
      <Icon name="add" size="sm" />
      Add to Calendar
    </A>
  );
}
