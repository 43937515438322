import get from "lodash/get";
import React from "react";

import { FlexSpaceBtw as ImportedFlexSpaceBtw, ItemHeading as ImportedItemHeading, Link } from "../../helpers";
import styled from "../../helpers/styled";
import { RsvpNavOptions } from "./Confirmation";

interface RsvpResponseTitleProps {
  pushRoute: (route: string) => void;
  track: (attributes: any) => void;
  rsvpNavOptions: RsvpNavOptions;
}

const LinkButton = styled(Link)(({ theme }) => ({
  fontSize: "1rem",
  ...get(theme, "fonts.s6"),
  "&:hover": {
    ...get(theme, "fonts.s6"),
  },
}));

const FlexSpaceBtw = styled(ImportedFlexSpaceBtw)`
  margin-top: 30px;
`;

const ItemHeading = styled(ImportedItemHeading)`
  margin-bottom: 0px;
`;

export function RsvpResponseTitle({ track, pushRoute, rsvpNavOptions }: RsvpResponseTitleProps) {
  return (
    <FlexSpaceBtw>
      <ItemHeading>Your RSVP response</ItemHeading>
      <LinkButton
        onClick={() => {
          track({ select: "rsvp initiated", rsvpPage: "rsvp confirmation", rsvpNavOptions });
          pushRoute("events/0");
        }}
        data-testid="update-response"
      >
        Update Response
      </LinkButton>
    </FlexSpaceBtw>
  );
}
