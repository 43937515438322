import React, { useContext } from "react";

import { Event, GuestContext } from "../../Components/Context";
import { Body } from "../../helpers";
import styled from "../../helpers/styled";
import { RsvpIcon } from "../Events/RsvpIcon";

export type LocationType = "first" | "middle" | "last";

export interface GuestResponseItemProps {
  rsvp: boolean | null;
  guestId: string;
  event: Event;
  location: LocationType;
}

const Wrapper = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: ${({ location }: any) => (location === "last" ? "25px" : "12px")};
  margin-top: ${({ location }: any) => (location === "first" ? "5px" : "0")};
`;

const GuestName = styled(Body)`
  font-size: 20px;
  margin: 0px 5px;
`;

const RsvpSelection = styled("span")`
  margin-right: 5px;
`;

export function GuestResponseItem({ guestId, rsvp, event, location }: GuestResponseItemProps) {
  const guests = useContext(GuestContext);
  const guest = guests.getGuestById(guestId);

  if (guest) {
    return (
      <Wrapper>
        <RsvpSelection data-testid="rsvp-selection">
          {rsvp ? <RsvpIcon isSelected type="accept" small /> : <RsvpIcon isSelected={false} type="reject" small />}
        </RsvpSelection>
        <GuestName>
          {guest.firstName} {guest.lastName}
        </GuestName>
      </Wrapper>
    );
  }

  return null;
}
