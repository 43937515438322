import { Global } from "@emotion/core";
import { compose } from "@xo-union/react-css-modules";
import React, { useContext, useEffect, useReducer, useState } from "react";

import styled from "../../helpers/styled";
import { AnalyticsContext, GuestContext } from "../../Components/Context";
import { FullWidth, IHistoryProps, Title } from "../../helpers";
import ThemedButton from "../../ThemedButton";
import { ThemedField } from "../../Components/FormField";

export type AddGuestsAction = { type: "ADD_GUEST"; payload: { guest: string; addNewGuest: boolean } };
type EditGuestAction = { type: "EDIT_GUEST"; payload: { guest: string; index: number } };

const Wrapper = styled("div")`
  margin-bottom: 13px;
`;

export function AddGuests({ pushRoute }: IHistoryProps) {
  const [showField, setShowField] = useState(false);
  const [newGuest, setNewGuest] = useState("");

  const [tempEditGuest, setTempEditGuest] = useState({ index: -1, guest: "" });
  const resetTempEdit = () => setTempEditGuest({ index: -1, guest: "" });

  const [addedGuests, dispatch] = useReducer(reducer, []);
  const { people, addGuestsToParty } = useContext(GuestContext);
  const { track } = useContext(AnalyticsContext);

  function reducer(state: string[], action: AddGuestsAction | EditGuestAction) {
    switch (action.type) {
      case "ADD_GUEST": {
        if (action.payload.guest && action.payload.guest.trim()) {
          if (action.payload.addNewGuest) {
            setNewGuest("");
          }
          return [...state, action.payload.guest.trim()];
        }
        return state;
      }
      case "EDIT_GUEST": {
        const notEmpty = action.payload.guest && action.payload.guest.trim() && action.payload.index >= 0;
        if (notEmpty) {
          resetTempEdit();
          return [
            ...state.slice(0, action.payload.index),
            action.payload.guest.trim(),
            ...state.slice(action.payload.index + 1),
          ];
        }
        resetTempEdit();
        return state;
      }
      default:
        return state;
    }
  }

  useEffect(() => {
    track({
      rsvpPage: "add guests",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function goToNextPage() {
    if (addedGuests.length) {
      track({
        selection: "update household",
        rsvpPage: "add guests",
      });
    }
    addGuestsToParty(addedGuests);
    pushRoute("events/0");
  }

  const addNewStyle = compose({
    input: "dashed-input",
  });

  const partyNumber = people.length + addedGuests.length;
  const limit = 8;
  const minCharLimit = 2;
  const enoughChar = newGuest.trim().length >= minCharLimit;

  return (
    <FullWidth>
      <Global
        styles={{
          ".dashed-input": {
            borderStyle: "dashed",
          },
        }}
      />
      <Title>Great! Who's coming from your household?</Title>
      {people.length
        ? people.map(guest => (
            <Wrapper key={guest.id}>
              <ThemedField name="Full Name" value={`${guest.firstName || ""} ${guest.lastName || ""}`} />
            </Wrapper>
          ))
        : null}

      {addedGuests.length
        ? addedGuests.map((guest, i) =>
            guest !== newGuest ? (
              <Wrapper key={guest}>
                <ThemedField
                  data-testid={`guest-${i + 1}-input`}
                  name="Full Name"
                  value={tempEditGuest.index === i ? tempEditGuest.guest : guest}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setTempEditGuest({ index: i, guest: e.target.value })
                  }
                  onBlur={() => dispatch({ type: "EDIT_GUEST", payload: tempEditGuest })}
                />
              </Wrapper>
            ) : null
          )
        : null}

      <Wrapper>
        {showField && partyNumber < limit && (
          <ThemedField
            data-testid="add-another-guest-input"
            name="Add another guest"
            value={newGuest}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewGuest(e.target.value)}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.keyCode === 13 && enoughChar) {
                setShowField(true);
                dispatch({ type: "ADD_GUEST", payload: { guest: newGuest, addNewGuest: true } });
              }
            }}
            onBlur={e => {
              if (!newGuest.trim() || !enoughChar || addedGuests.includes(newGuest)) return;

              if (e.relatedTarget && e.relatedTarget.textContent === "Continue") {
                dispatch({ type: "ADD_GUEST", payload: { guest: newGuest, addNewGuest: false } });
              } else {
                setShowField(false);
                dispatch({ type: "ADD_GUEST", payload: { guest: newGuest, addNewGuest: true } });
              }
            }}
            classes={addNewStyle}
          />
        )}
      </Wrapper>
      <ThemedButton
        color="secondary"
        text="Add Guest"
        disabled={(partyNumber >= limit - 1 && showField) || partyNumber >= limit}
        onClick={() => setShowField(true)}
      />
      <ThemedButton text="Continue" onClick={goToNextPage} />
    </FullWidth>
  );
}
