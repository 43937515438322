import get from "lodash-es/get";
import React from "react";

import styled from "./helpers/styled";
import { BaseButton } from "./helpers/CSSHelpers";

export type ButtonType = "button" | "submit" | "reset" | undefined;

interface IThemedButton {
  text: string;
  size: "lg" | "md" | "sm";
  disabled: boolean;
  marginBottom?: boolean;
  testId?: string;
  type?: ButtonType;
  color?: string;
  onClick?: () => void;
}

interface ButtonProps {
  type?: ButtonType;
  disabled?: boolean;
  color?: string;
  onClick?: () => void;
  style?: object;
}

const getSecondaryButtonStyles = (buttonStyles, primaryColor, buttonTextColor) => {
  const secondaryStyles = {
    ...buttonStyles,
    color: primaryColor,
    borderColor: primaryColor,
    backgroundColor: buttonTextColor,
    background: buttonTextColor,
  };

  return secondaryStyles;
};

const StyledButton = styled(BaseButton)<ButtonProps>(({ theme, color }) => {
  let buttonStyles = get(theme, ["components", "Button"]);
  const hoverStyles = get(theme, ["components", "ButtonHover"]);
  const buttonColor = get(theme, ["colors", "primaryHover"]);
  const buttonTextColor = get(theme, ["colors", "background"]);
  const fonts = get(theme, "fonts.s6");
  const primaryColor = get(theme, ["colors", "primary"]);

  if (buttonStyles && color === "secondary") {
    buttonStyles = getSecondaryButtonStyles(buttonStyles, primaryColor, buttonTextColor);
  }

  return {
    ...fonts,
    position: "relative",
    marginTop: "20px",
    backgroundColor: buttonColor,
    border: "1px solid",
    borderColor: get(buttonStyles, ["backgroundColor"]),
    color: buttonTextColor,
    ...buttonStyles,
    "&:hover": {
      ...hoverStyles,
    },
    "&:disabled, &:hover:disabled": {
      opacity: 0.5,
      cursor: "default",
      ...buttonStyles,
    },
  };
});

function ThemedButton({ onClick, text, disabled, type, testId, marginBottom, color }: IThemedButton) {
  const inlineStyles = {
    marginBottom: marginBottom ? "20px" : "0px",
  };

  return (
    <StyledButton
      type={type}
      disabled={disabled}
      data-testid={testId}
      onClick={onClick}
      style={inlineStyles}
      color={color}
    >
      {text}
    </StyledButton>
  );
}

ThemedButton.defaultProps = {
  disabled: false,
  color: "primary",
  size: "lg",
  marginBottom: true,
};

export default ThemedButton;
