import { withTheme } from "emotion-theming";
import { useEffect } from "react";
import get from "lodash/get";

const fontWeightConversions = {
  light: "300",
  normal: "400",
  semibold: "600",
  bold: "700",
};
const convertFontWeight = weight =>
  Object.keys(fontWeightConversions).includes(weight) ? fontWeightConversions[weight] : weight;

const fontFamily = f => get(f, "fontFamily");
const fontWeight = f => get(f, "fontWeight");

export const GoogleFonts = withTheme(props => {
  const themeFonts = get(props, ["theme", "fonts"]);
  if (!themeFonts) return null;
  const fonts = Object.values(props.theme.fonts);
  const distinctFontFamilies = [...new Set(fonts.map(f => fontFamily(f)))];
  const filteredFamilies = distinctFontFamilies.filter(
    fam => !["Arial", "Courier", "Georgia", "Lucida Sans", "Times New Roman", "Trebuchet MS"].includes(fam)
  );
  if (filteredFamilies.length === 0) return null;

  const fontWeights = filteredFamilies.map(family => {
    const weights = fonts
      .filter(f => fontFamily(f) === family)
      .map(f => fontWeight(f))
      .map(convertFontWeight);
    const weightString = [...new Set(weights)].join(",");
    return `${family}:${weightString}`;
  });
  const query = fontWeights.map(s =>
    s
      .replace(/\s/g, "+")
      .replace(/%20/g, "+")
      .replace(/'/g, "")
  );
  const lastIndex = query.length - 1;
  query[lastIndex] = `${query[lastIndex]}&display=swap`;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (typeof window !== "undefined") {
      // eslint-disable-next-line
      const WebFont = require("webfontloader");
      WebFont.load({ google: { families: query } });
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return null;
});
