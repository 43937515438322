import { Global } from "@emotion/core";
import React from "react";

import styled from "../../helpers/styled";
import { Title } from "../../helpers";

interface Props {
  numberOfMatches: number;
}

const PartialMatchTitle = styled(Title)`
  font-size: 28px;
`;

export function PartialMatchHeading({ numberOfMatches }: Props) {
  return (
    <>
      <Global
        styles={{
          ".display-none": {
            display: "none",
          },
        }}
      />
      <PartialMatchTitle>
        {numberOfMatches > 1
          ? "We've found more than one match in the guest list. Please select your name from the list below."
          : "We’ve found you in the guest list. Please confirm your name below to continue with your RSVP."}
      </PartialMatchTitle>
    </>
  );
}
