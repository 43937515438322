import React from "react";
import Icon from "@xo-union/tk-component-icons";
import { varTextDefault } from "@xo-union/tk-ui-colors/lib/variables.css";

import styled from "../helpers/styled";

interface PreviewBannerProps {
  restart: boolean;
  pushRoute: (route: string, state?: any) => void;
}

const RestartText = styled("span")`
  color: ${varTextDefault};
  cursor: pointer;
  text-decoration: underline;
`;

const TextWrapper = styled("div")`
  color: ${varTextDefault};
  font-size: 16px;
  margin: 10px auto;
  text-align: center;
`;

const StyledBanner = styled("div")`
  background-color: white;
  bottom: 0px;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.25);
  height: 53px;
  left: 0px;
  position: absolute;
  width: 100%;
`;

const PreviewBanner = ({ restart, pushRoute }: PreviewBannerProps) => {
  return (
    <StyledBanner>
      <TextWrapper>
        {restart ? (
          <>
            <Icon size="md" name="refresh" />
            <RestartText onClick={() => pushRoute("")}>Preview as Another Guest</RestartText>
          </>
        ) : (
          <>
            <Icon size="sm" name="info" />
            Preview as Test Guest or enter any name from your Guest List
          </>
        )}
      </TextWrapper>
    </StyledBanner>
  );
};

PreviewBanner.defaultProps = {
  restart: false,
  pushRoute: () => {},
};

export default PreviewBanner;
