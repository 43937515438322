import { Person } from "../Components/Context";

export function findUnnamedGuests(guest: Person): boolean {
  const plusOneRegex = /('s.)?\+1/gi;
  const guestRegex = /guest(s)?/gi;
  const plusOneLetterRegex = /plus(.?)one/gi;
  const plusOneNumberRegex = /plus(.?)1/gi;

  if ((guest.lastName && plusOneRegex.test(guest.lastName)) || plusOneRegex.test(guest.firstName)) {
    return true;
  }

  if ((guest.lastName && plusOneLetterRegex.test(guest.lastName)) || plusOneLetterRegex.test(guest.firstName)) {
    return true;
  }

  if ((guest.lastName && guestRegex.test(guest.lastName)) || guestRegex.test(guest.firstName)) {
    return true;
  }

  if ((guest.lastName && plusOneNumberRegex.test(guest.lastName)) || plusOneNumberRegex.test(guest.firstName)) {
    return true;
  }

  return false;
}

export function partyHasUnnamedGuest(people: Person[]): boolean {
  return !!people.find(person => findUnnamedGuests(person) || person.edited);
}
