import React, { useContext } from "react";

import styled from "../../helpers/styled";
import { WeddingContext } from "../../Components/Context/WeddingContext";
import { Title } from "../../helpers";

export interface IEditNameTitleProps {
  leaderName: string;
}

const Wrapper = styled("div")`
  margin-bottom: 20px;
`;

export function EditNameTitle({ leaderName }: IEditNameTitleProps) {
  const { wedding } = useContext(WeddingContext);

  return (
    <Wrapper>
      <Title>
        Hi {leaderName}, let {wedding ? wedding.wedding_name : "the Couple"} know who is coming with you!
      </Title>
    </Wrapper>
  );
}
