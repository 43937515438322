import { CreateStyled } from "@emotion/styled-base";
import styled from "@emotion/styled/macro";

interface Colors {
  background: string;
  primary: string;
  primaryHover: string;
  primaryDisabled: string;
  secondary: string;
  secondaryHover: string;
  secondaryDisabled: string;
  tertiary: string;
  tertiaryHover: string;
  tertiaryDisabled: string;
  alert: string;
}

interface Components {
  BackgroundImage: any;
  BottomImage: any;
  Button: any;
  ButtonHover: any;
  ContentItem: any;
  DateLocationLockup: any;
  Dropdown: any;
  Event: any;
  Footer: any;
  HeadlineLarge: any;
  HeadlineSmall: any;
  Hero: any;
  InitialsDateLockup: any;
  Input: any;
  Link: any;
  LinkHover: any;
  Page: any;
  Photo: any;
  PhotoGallery: any;
  SiteSubtitle: any;
  SiteTitle: any;
  SubEvent: any;
  TopImage: any;
  WeddingParty: any;
  WeddingPartyPerson: any;
  Nav: any;
}

interface Config {
  headerOnHero: boolean;
}

interface Font {
  fontFamily: string;
  color: string;
  fontSize: string;
  fontWeight: string;
  letterSpacing: string;
}

interface Fonts {
  body: Partial<Font>;
  nav: Partial<Font>;
  tiny: Partial<Font>;
  s1: Partial<Font>;
  s2: Partial<Font>;
  s3: Partial<Font>;
  s4: Partial<Font>;
  s5: Partial<Font>;
  s6: Partial<Font>;
}

interface Images {
  background: string;
  bottom: string;
  top: string;
}

interface Picture {
  smUrl: string;
  mdUrl: string;
  lgUrl: string;
  xlUrl: string;
}

interface ResponsiveImages {
  background: Partial<Picture>;
  bottom: Partial<Picture>;
  top: Partial<Picture>;
}

interface Separator {
  separator: string;
}

export type ITheme = {
  white: string;
  "gray-8": string;
  "gray-7": string;
  "gray-5": string;
  "gray-4": string;
  black: string;
  "knot-blue-darker-20": string;
  colors: Partial<Colors>;
  config: Partial<Config>;
  components: Partial<Components>;
  fonts: Partial<Fonts>;
  images: Partial<Images>;
  responsiveImages: ResponsiveImages;
  separator: Partial<Separator>;
};

export default styled as CreateStyled<ITheme>;
