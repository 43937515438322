import React from "react";
import get from "lodash/get";
import Icon from "@xo-union/tk-component-icons";

import styled from "../helpers/styled";

interface SelectButtonProps {
  onClick: () => void;
  selected: boolean | null;
}

interface ISelectButton extends SelectButtonProps {
  testId: string;
  text: string;
  subText?: string;
}

const IconWrapper = styled("div")`
  margin-left: auto;
  width: 1.375rem;
  height: 1.375rem;
  flex: 0 0;
  align-self: center;
`;

// to prevent text from overflowing to where the icon would be
const TextWrapper = styled("div")`
  max-width: calc(100% - 2rem);
`;

const StyleSelectButton = styled("button")<SelectButtonProps>({}, ({ theme, selected }) => {
  const primaryColor = get(theme, "colors.primary", "black");
  const backgroundColor = get(theme, "colors.background", "white");

  return {
    ...get(theme, "fonts.s6", {}),
    ...get(theme, "components.Button"),
    fontSize: "1.25rem", // override font size to 20px
    textTransform: "none", // needed to remove uppercase from theme styles
    cursor: "pointer",
    width: "100%",
    padding: "1rem 1.25rem",
    margin: ".625rem 0",
    border: `1px solid ${primaryColor}`,
    borderRadius: "6px",
    textAlign: "left",
    backgroundColor: !selected ? backgroundColor : primaryColor,
    color: !selected ? primaryColor : backgroundColor,
    opacity: selected === null ? 0.5 : 1,
    display: "flex",
    position: "relative",
    "&:hover": {
      color: get(theme, "components.ButtonHover.color"),
      backgroundColor: get(theme, "components.ButtonHover.backgroundColor"),
    },
  };
});

const SubText = styled("div")({}, ({ theme }) => ({
  ...get(theme, "fonts.s6", {}),
  color: "currentColor",
  marginTop: "4px",
}));

const SelectButton = (props: ISelectButton) => {
  const { text, subText, onClick, selected, testId } = props;
  return (
    <StyleSelectButton onClick={onClick} selected={selected} data-testid={testId}>
      <TextWrapper>
        {text}
        {subText && <SubText>{subText}</SubText>}
      </TextWrapper>
      <IconWrapper>{selected && <Icon size="sm" name="checkmark_circle" />}</IconWrapper>
    </StyleSelectButton>
  );
};

export default SelectButton;
