import analytics from "@xo-union/tk-analytics/lib/wrapper";
import Spinner from "@xo-union/tk-component-spinner";
import { AxiosResponse } from "axios";
import honeybadger from "honeybadger-js";
import get from "lodash-es/get";
import React, { useContext, useEffect, useReducer } from "react";

import { getNextEventUrl as getNextUrl, IContextProperProps, IFrom, systemError } from "../../helpers";
import { Layout } from "../../Layout";
import { ApiError } from "../ApiError";
import { WarningToast } from "../WarningToast";
import eventService from "./eventService";
import { WeddingContext } from "./WeddingContext";
import { IAttendanceMap } from "./IGuestContext";

export interface IEvent {
  isPrivateRsvp: boolean;
  rsvpDeadline: string;
  eventCount: number;
  rsvpableCount: number;
  usesQuestions: boolean;
  allowGuestPreview: boolean;
  events: Event[];
  guestWeddingQuestions: Question[];
  rsvpAsAPage?: boolean;
  rsvpPageHidden?: boolean;
}

export interface Event {
  id: string;
  weddingId: string;
  name: string;
  type: string;
  date?: string;
  time?: string;
  attire?: string;
  notes?: string;
  order: number;
  requireRsvp: boolean;
  sameVenue: boolean;
  visible: boolean;
  meals?: string[];
  location?: Location;
  createdAt: string;
  updatedAt: string;
  questions?: Question[];
}

export interface Question {
  id: string;
  type: string;
  answerType: string;
  text: string;
  options?: Option[];
}

export interface Option {
  id: string;
  text: string;
  description: string;
}

export interface Location {
  fullAddress?: string;
  name?: string;
}

export type INextEventUrl = (currentIndex: string, from: IFrom) => string;

export type IGetEventDetails = (eventId: string) => Event | undefined;
type IGetAllQuestions = () => Map<string, Question> | undefined;
export type IRequestGuestAtendance = (householdId: string) => Promise<IAttendanceMap | undefined> | undefined;

export interface IEventProviderContext {
  weddingSlug: string;
  weddingUuid: string;
  eventBody?: IEvent;
  events: Event[];
  getNextEventUrl: INextEventUrl;
  getEventDetails: IGetEventDetails;
  getAllQuestions: IGetAllQuestions;
  requestGuestAttendance: IRequestGuestAtendance;
}

type IErrorType = "no-rsvp" | "unexpected" | "";

interface EventState {
  loading: boolean;
  eventBody?: IEvent;
  hasError: boolean;
  errorType: IErrorType;
}

type EventActions = { type: "SET_EVENTS"; payload: IEvent } | { type: "SET_ERROR"; payload: IErrorType };

const defaultValue = {
  isLoading: false,
  eventBody: undefined,
  events: [],
  weddingSlug: "",
  weddingUuid: "",
  getNextEventUrl: () => "",
  getEventDetails: () => undefined,
  getAllQuestions: () => undefined,
  requestGuestAttendance: () => undefined,
};

export const EventContext = React.createContext<IEventProviderContext>(defaultValue);

export const EventConsumer = EventContext.Consumer;

function reducer(state: EventState, action: EventActions): EventState {
  switch (action.type) {
    case "SET_EVENTS": {
      return { ...state, loading: false, eventBody: action.payload, hasError: false, errorType: "" };
    }

    case "SET_ERROR": {
      return { ...state, loading: false, eventBody: undefined, hasError: true, errorType: action.payload };
    }

    default:
      throw new Error("No Event Action defined");
  }
}

const initialState: EventState = { loading: true, eventBody: undefined, hasError: false, errorType: "" };

export function EventProvider(props: IContextProperProps) {
  const [{ eventBody, loading, hasError, errorType }, dispatch] = useReducer<
    (state: EventState, action: EventActions) => EventState
  >(reducer, initialState);

  const { wedding, weddingSlug, weddingUuid } = useContext(WeddingContext);

  useEffect(() => {
    async function callApi() {
      try {
        const weddingUuid = wedding ? wedding.wedding_uuid : "";
        const { data }: AxiosResponse<IEvent> = await eventService.searchForEvent(weddingUuid);
        honeybadger.setContext({ events: data.events });
        props.addEvents(data.events);

        dispatch({ type: "SET_EVENTS", payload: data });
      } catch (error) {
        if (
          error.response &&
          error.response.status === 404 &&
          error.response.data &&
          error.response.data.error === "no events that require rsvp"
        ) {
          dispatch({ type: "SET_ERROR", payload: "no-rsvp" });
        } else {
          dispatch({ type: "SET_ERROR", payload: "unexpected" });
        }

        honeybadger.notify(error, {
          name: "Event API",
          context: {
            apiResponse: error,
          },
        });
        analytics.track("Error Message Displayed", {
          message: systemError,
          source: "introduction",
          errorType: "events API failed",
          product: "rsvp",
        });
      }
    }

    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getNextEventUrl(currentIndex: string, from: IFrom): string {
    if (eventBody) {
      return getNextUrl(Number(currentIndex), eventBody.events, props.invitations, from);
    }

    return "";
  }

  function getEventDetails(eventId: string): Event | undefined {
    return eventBody && eventBody.events.find(e => e.id === eventId);
  }

  const getAllQuestions = React.useCallback(() => {
    if (!eventBody) return undefined;
    const questMap = new Map<string, Question>();
    const { guestWeddingQuestions, events } = eventBody;
    (guestWeddingQuestions || []).forEach(q => {
      questMap.set(q.id, q);
    });
    (events || []).forEach(e => {
      if (e.questions && e.questions.length) {
        e.questions.forEach(q => {
          questMap.set(q.id, q);
        });
      }
    });
    return questMap;
  }, [eventBody]);

  const requestGuestAttendance = async householdId => {
    if (!eventBody?.allowGuestPreview) return;
    const { data } = await eventService.getAttendanceList(weddingUuid, householdId);
    return data;
  };

  const weddingName = get(wedding, "wedding_name");

  return (
    <EventContext.Provider
      value={{
        eventBody,
        events: eventBody ? eventBody.events : [],
        weddingSlug,
        weddingUuid,
        getNextEventUrl,
        getEventDetails,
        getAllQuestions,
        requestGuestAttendance,
      }}
    >
      {loading && (
        <Layout noTrack {...props}>
          <Spinner />
        </Layout>
      )}
      {hasError && errorType === "no-rsvp" && (
        <Layout noTrack {...props}>
          <WarningToast testId="disabled-rsvp">
            {weddingName} are no longer collecting RSVPs online. Please contact the couple directly.
          </WarningToast>
        </Layout>
      )}
      {hasError && errorType === "unexpected" && (
        <Layout noTrack {...props}>
          <ApiError testId="events-error" />
        </Layout>
      )}
      {!loading && !hasError && props.children}
    </EventContext.Provider>
  );
}
