import analytics from "@xo-union/tk-analytics/lib/wrapper";
import Spinner from "@xo-union/tk-component-spinner";
import { ThemeProvider } from "emotion-theming";
import honeybadger from "honeybadger-js";
import get from "lodash-es/get";
import React, { useEffect, useReducer, useContext } from "react";
import { matchPath } from "react-router";
import { PreviewContext } from "./PreviewContext";

import {
  getThemeWithRsvpOverrides,
  GoogleFonts,
  IHistoryPropsNoPush,
  isCustomDomain,
  systemError,
} from "../../helpers";
import theme from "../../helpers/Theme";
import { Layout } from "../../Layout";
import { ApiError } from "../ApiError";
import WeddingService from "./WeddingService";
import ThemeStylePreviewService from "./ThemeStylePreviewService";

export interface Accommodation {
  id: number;
  member_wedding_id: number;
  type: string;
  name: null | string;
  notes: string;
  website: string;
  email: null | string;
  phone: null | string;
  expedia_id: null;
}

export interface CustomDomain {
  id: number;
  name: string;
  is_active: boolean;
  created_at: string;
  updated_at: string;
  member_wedding_id: number;
  expiration_date: string;
  date_of_last_renewal: null;
  registration_status: null;
}

export interface CoverPhoto {
  id: number;
  member_wedding_id: number;
  media_api_id: string;
  height: number;
  width: number;
  created_at: string;
  updated_at: string;
  image_url: string | null;
  rotation_angle: string;
  crop_box: string;
  mobile_crop_box: string | null;
}
export interface IWedding {
  id: string;
  fiance_first_name: string | null;
  fiance_last_name: string | null;
  first_name: string | null;
  last_name: string | string;
  rsvp_deadline_date: string | null;
  slug: string;
  user_uuid: string;
  vanity_url: string;
  wedding_date: string | null;
  wedding_name: string | null;
  wedding_uuid: string;
  wedding_location: string | null;
  cover_photo: CoverPhoto | null;
  accommodations: Accommodation[];
  days_until_wedding: number;
  event_count: number;
  rsvp_on_count: number;
  custom_domain: CustomDomain | null;
  theme_styles: ThemeStyles | null;
  theme_name: string;
  theme_id: number;
  hide_date: boolean;
  announcement: object;
}

export interface IWeddingContextState {
  wedding: IWedding | null;
  weddingSlug: string;
  weddingUuid: string;
}

export interface ThemeStyles {
  config: object;
  components?: object;
  colors: {
    hover: string;
    background: string;
  };
  fonts: {
    body: object;
    title: object;
    itemTitle: object;
    sectionTitle: object;
  };
  images: object;
  separator: object;
  RSVP: object;
}

const defaultValue = {
  wedding: null,
  weddingSlug: "",
  weddingUuid: "",
};

export const WeddingContext = React.createContext<IWeddingContextState>(defaultValue);

export const WeddingConsumer = WeddingContext.Consumer;

type WeddingState = { loading: boolean; wedding: IWedding | null; hasError: boolean };

type WeddingActions = { type: "SET_WEDDING"; payload: IWedding } | { type: "SET_ERROR"; payload: boolean };

function reducer(state: WeddingState, action: WeddingActions) {
  switch (action.type) {
    case "SET_WEDDING": {
      return { ...state, loading: false, wedding: action.payload, hasError: false };
    }
    case "SET_ERROR": {
      return { ...state, loading: false, wedding: null, hasError: true };
    }

    default:
      throw new Error("No Wedding Event Defined");
  }
}

const initialState = { loading: true, wedding: null, hasError: false };

export function WeddingProvider(props: IHistoryPropsNoPush) {
  const [{ loading, wedding, hasError }, dispatch] = useReducer(reducer, initialState);
  const { preview, previewTheme } = useContext(PreviewContext);

  function goToNotFoundPage() {
    window.location.assign(`${process.env.REACT_APP_WWS_URL}/not_found`);
  }

  useEffect(() => {
    async function callApi() {
      try {
        if (isCustomDomain(window.location.hostname)) {
          const domain = window.location.hostname.replace("www.", "");
          const { data: wedding } = await WeddingService.getWeddingDetails({ domain });
          honeybadger.setContext({ wedding });
          if (wedding) {
            if (window.themeOverride) {
              wedding.theme_styles = window.themeOverride;
            }
            dispatch({ type: "SET_WEDDING", payload: wedding });
          } else {
            goToNotFoundPage();
          }
        } else if (props.match.params && props.match.params.weddingSlug) {
          const { data: wedding } = await WeddingService.getWeddingDetails({
            wedding_slug: props.match.params.weddingSlug,
          });
          honeybadger.setContext({ wedding });
          if (wedding) {
            if (window.themeOverride) {
              wedding.theme_styles = window.themeOverride;
            }
            dispatch({ type: "SET_WEDDING", payload: wedding });
          } else {
            goToNotFoundPage();
          }
        } else {
          // No domain or slug, redirect to WWS
          const match = matchPath<{ weddingSlug: string }>(window.location.pathname, {
            path: "/us/:weddingSlug",
          });

          if (match && match.params.weddingSlug) {
            window.location.assign(`${process.env.REACT_APP_WWS_URL}/${match.params.weddingSlug}`);
          } else {
            goToNotFoundPage();
          }
        }
        honeybadger.setContext({
          domain: window.location.hostname,
          slug: props.match.params.weddingSlug,
        });
      } catch (error) {
        honeybadger.notify(error, {
          name: "Wedding Details API",
          message: "Wedding Details API failed to respond",
          context: {
            domain: window.location.hostname,
            slug: props.match.params.weddingSlug,
            apiResponse: error,
          },
        });
        dispatch({ type: "SET_ERROR", payload: true });
        analytics.track("Error Message Displayed", {
          message: systemError,
          source: "introduction",
          errorType: "wedding details API failed",
          product: "rsvp",
        });
      }
    }

    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function getStyleData() {
      if (preview && previewTheme && wedding && wedding.theme_id !== previewTheme) {
        const { data } = await ThemeStylePreviewService.getThemeStyle(previewTheme);
        const theme_styles = get(data, ["data", "theme", "themeStyles", "0", "styles"]);
        dispatch({ type: "SET_WEDDING", payload: { ...wedding, theme_styles, theme_id: previewTheme } });
      }
    }
    getStyleData();
  }, [wedding, preview, previewTheme]);

  const weddingTheme = wedding && getThemeWithRsvpOverrides(wedding);

  return (
    <WeddingContext.Provider
      value={{
        wedding,
        weddingSlug: get(wedding, "slug", ""),
        weddingUuid: get(wedding, "wedding_uuid", ""),
      }}
    >
      <ThemeProvider theme={{ ...theme, ...weddingTheme }}>
        {loading && (
          <Layout noTrack {...props}>
            <Spinner />
          </Layout>
        )}
        {hasError && (
          <Layout noTrack {...props}>
            <ApiError testId="wedding-details-error" />
          </Layout>
        )}
        {!loading && !hasError && wedding && (
          <React.Fragment>
            <GoogleFonts />
            {props.children}
          </React.Fragment>
        )}
      </ThemeProvider>
    </WeddingContext.Provider>
  );
}
