import honeybadger from "honeybadger-js";
import Cookies from "js-cookie";
import get from "lodash-es/get";
import { parse } from "query-string";
import React from "react";
import v4 from "uuid";

import {
  areNamesEmpty as areNamesEmptyHelper,
  findPartyLeader,
  IContextProperProps,
  Optional,
  splitFullName,
  addInvitationResponsesToGuests,
} from "../../helpers";
import { allGuestsRespondedInParty } from "../../helpers/allGuestsRespondedInParty";
import { Event, EventContext } from "./EventContext";
import { GuestSearchService } from "./GuestSearchService";
import {
  IGetGuestsForWeddingParams,
  IGuestApi,
  IGuestContextState,
  IGuestProviderState,
  IHouseholdLeader,
  Invitation,
  Match,
  MatchedSubmitBody,
  Person,
  IAnswerResponse,
} from "./IGuestContext";
import { InvitationContext } from "./InvitationContext";

const defaultState = {
  exactMatch: null,
  partialMatches: [],
  people: [],
  householdLeader: null,
  guestAttendance: null,
};

const defaultValue = {
  ...defaultState,
  submitRsvpBody: null,
  getGuestsForWedding: () => Promise.reject(),
  setGidCookie: () => {},
  getGuestById: () => undefined,
  updateGuestName: () => {},
  areNamesEmpty: () => true,
  resetState: () => {},
  updateRsvpBodyContact: () => {},
  addGuestsToParty: () => {},
  selectPartialMatch: () => undefined,
  updateHousehold: () => {},
  updateHouseholdAnswers: () => {},
  getGuestAttendanceList: () => {},
  setGuestToContext: () => {},
};

export const GuestContext = React.createContext<IGuestContextState>(defaultValue);

export const GuestConsumer = GuestContext.Consumer;

const UPDATE_HOUSEHOLD = "UPDATE_HOUSEHOLD";
const UPDATE_HOUSEHOLD_LEADER = "UPDATE_HOUSEHOLD_LEADER";
const GET_WEDDING_GUESTS = "GET_WEDDING_GUESTS";
const SET_PUBLIC_HOUSEHOLD_LEADER = "SET_PUBLIC_HOUSEHOLD_LEADER";
const UPDATE_GUEST_FIELDS = "UPDATE_GUEST_FIELDS";
const RESET_STATE = "RESET_STATE";
const ADD_GUESTS_TO_PARTY = "ADD_GUESTS_TO_PARTY";
const SELECT_PARTIAL_MATCH = "SELECT_PARTIAL_MATCH";
const UPDATE_HOUSEHOLD_ANSWERS = "UPDATE_HOUSEHOLD_ANSWERS";
const SET_GUEST_ATTENDANCE = "SET_GUEST_ATTENDANCE";

const reducer = (state, action): IGuestProviderState => {
  switch (action.type) {
    case SET_GUEST_ATTENDANCE:
    case UPDATE_HOUSEHOLD_ANSWERS:
    case SELECT_PARTIAL_MATCH:
    case ADD_GUESTS_TO_PARTY:
    case RESET_STATE:
    case UPDATE_GUEST_FIELDS:
    case SET_PUBLIC_HOUSEHOLD_LEADER:
    case GET_WEDDING_GUESTS:
    case UPDATE_HOUSEHOLD_LEADER:
    case UPDATE_HOUSEHOLD:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const GuestProvider = (props: IContextProperProps) => {
  const { eventBody, weddingUuid, events, requestGuestAttendance } = React.useContext(EventContext);
  const { invitations } = React.useContext(InvitationContext);
  const [state, dispatch] = React.useReducer(reducer, defaultState);

  const updateHouseholdLeader = (exactMatch: Match | null): boolean => {
    const people = (exactMatch && exactMatch.people) || [];
    const partyLeader = findPartyLeader(people);

    if (partyLeader) {
      let householdLeader: IHouseholdLeader = partyLeader;

      props.addGuestsToEvents(people);

      dispatch({ type: UPDATE_HOUSEHOLD_LEADER, payload: { householdLeader } });
      return true;
    }
    return false;
  };

  const setPublicHouseholdLeader = (fullName: string): void => {
    const { firstName, lastName } = splitFullName(fullName);
    const householdLeader: IHouseholdLeader = {
      firstName,
      lastName,
    };

    const invitations: Invitation[] = events.map((event: Event) => ({
      eventId: event.id,
      rsvp: null,
    }));

    const people: Person[] = [
      {
        firstName,
        lastName,
        invitations,
        id: v4(),
        isNew: true,
      },
    ];
    props.addGuestsToEvents(people);

    dispatch({ type: SET_PUBLIC_HOUSEHOLD_LEADER, payload: { householdLeader, people } });
  };

  const setGuestToContext = (guests: IGuestApi) => {
    const people: Person[] = get(guests, ["exactMatch", "people"], []);
    dispatch({ type: GET_WEDDING_GUESTS, payload: { ...guests, people } });

    const leader = people.find(person => person.isLeader);
    setPublicHouseholdLeader(`${leader?.firstName} ${leader?.lastName}`);
  };

  const getGuestsForWedding = async (params: IGetGuestsForWeddingParams): Promise<IGuestApi> => {
    try {
      const { data: guests } = await GuestSearchService.searchForGuest({
        weddingUuid,
        ...params,
      });

      const people: Person[] = get(guests, "exactMatch.people", []);

      dispatch({ type: GET_WEDDING_GUESTS, payload: { ...guests, people } });
      const potentialMatches = get(guests, "potential_matches", []);

      if ((people.length || !potentialMatches.length) && !updateHouseholdLeader(guests.exactMatch)) {
        if (params.full_name && eventBody && !eventBody.isPrivateRsvp) {
          setPublicHouseholdLeader(params.full_name);
        }
      }

      return guests;
    } catch (error) {
      if (
        error.response &&
        error.response.status === 404 &&
        error.response.data &&
        error.response.data.error === "no exact or partial matches"
      ) {
        setPublicHouseholdLeader(params.full_name ? params.full_name : "");
        return Promise.resolve({ exactMatch: null, partialMatches: null });
      } else {
        honeybadger.notify(error, {
          name: "Guest Search API",
          message: "Guest Search API failed",
          context: {
            params,
            apiResponse: error,
          },
        });
        return Promise.reject([]);
      }
    }
  };

  const setGidCookie = async (): Promise<void> => {
    const qs = parse(props.location.search);
    const cookie = Cookies.get("gid");
    try {
      if (qs.gid && !Array.isArray(qs.gid)) {
        const { exactMatch } = await getGuestsForWedding({ id: qs.gid });
        if (exactMatch && exactMatch.people && exactMatch.people.length) {
          Cookies.set("gid", qs.gid || "", { expires: 183 });
          if (allGuestsRespondedInParty(exactMatch.people)) {
            props.pushRoute("confirmation", { completedRsvp: true });
          } else {
            props.pushRoute("events/0");
          }
        }
      } else if (cookie) {
        const { exactMatch } = await getGuestsForWedding({ id: cookie });
        if (exactMatch && exactMatch.people && exactMatch.people.length) {
          if (allGuestsRespondedInParty(exactMatch.people)) {
            props.pushRoute("confirmation", { completedRsvp: true });
          } else {
            props.pushRoute("events/0");
          }
        }
      }
    } catch (error) {
      return Promise.reject([]);
    }
  };

  const getGuestById = (guestId: string): Person | undefined =>
    state.people.find((guest: Person) => guest.id === guestId);

  const updateFieldsOnGuest = (guestId: string, fields: Optional<Person>) => {
    const { people }: IGuestProviderState = state;
    const guest = people.find(g => g.id === guestId);
    if (guest) {
      const index = people.findIndex(g => g.id === guestId);
      const newGuest: Person = { ...guest, ...fields, edited: true };
      const updatedPartyGuests = [...people];
      updatedPartyGuests[index] = newGuest;

      dispatch({ type: UPDATE_GUEST_FIELDS, payload: { people: updatedPartyGuests } });
    }
  };

  const updateGuestName = (guestId: string, fullName: string) => {
    const { firstName, lastName } = splitFullName(fullName);
    updateFieldsOnGuest(guestId, { firstName, lastName });
  };

  const areNamesEmpty = (): boolean => areNamesEmptyHelper(state.people);

  const resetState = () => {
    dispatch({ type: RESET_STATE, payload: defaultState });
  };

  const addGuestsToParty = (guests: string[]) => {
    const { people, householdLeader }: IGuestProviderState = state;

    const invitations: Invitation[] = events.map((event: Event) => ({
      eventId: event.id,
      rsvp: null,
    }));

    const newGuests: Person[] = guests.map(guest => {
      const { firstName, lastName } = splitFullName(guest);
      return {
        id: v4(),
        firstName,
        lastName,
        invitations,
        isNew: true,
      };
    });

    let leader: IHouseholdLeader | null = householdLeader;
    if (!leader) {
      leader = newGuests[0];
    }

    const finalParty = [...people, ...newGuests];

    dispatch({ type: ADD_GUESTS_TO_PARTY, payload: { people: finalParty, householdLeader: leader } });
    props.addGuestsToEvents(finalParty);
  };

  const selectPartialMatch = (householdId: string): Match | undefined => {
    const { partialMatches }: IGuestProviderState = state;

    if (partialMatches) {
      const exactMatch = partialMatches.find(match => match.id === householdId);

      if (exactMatch) {
        dispatch({ type: SELECT_PARTIAL_MATCH, payload: { exactMatch, people: exactMatch.people } });
        updateHouseholdLeader(exactMatch);
        return exactMatch;
      }

      return undefined;
    }

    return undefined;
  };

  const updateHousehold = (household: Match): void => {
    dispatch({ type: UPDATE_HOUSEHOLD, payload: { people: household.people, exactMatch: household } });
    updateHouseholdLeader(household);
  };

  const updateHouseholdAnswers = (answer: IAnswerResponse): void => {
    if (!answer) return;
    const { exactMatch }: IGuestProviderState = state;
    const { answers: currentAnswers } = exactMatch || {};
    // Remove previous answer from household so no multiple answers
    const withoutPrevious = (currentAnswers || []).filter(a => a.questionId !== answer.questionId);
    // Add new answer to household
    const updatedAnswers = [...withoutPrevious, answer];
    const updatedHousehold = { ...exactMatch, answers: updatedAnswers };
    dispatch({ type: UPDATE_HOUSEHOLD_ANSWERS, payload: { exactMatch: updatedHousehold } });
  };

  const submitRsvpBody: MatchedSubmitBody = {
    weddingId: state?.exactMatch?.weddingId || weddingUuid || "",
    people: addInvitationResponsesToGuests(state.people, invitations, eventBody && eventBody.isPrivateRsvp),
    answers: state?.exactMatch?.answers || undefined,
  };

  const getGuestAttendanceList = async () => {
    const { exactMatch } = state;
    if (!exactMatch?.id) return;
    if (state.guestAttendance !== null) return;
    try {
      const guestAttendance = await requestGuestAttendance(exactMatch.id);
      dispatch({ type: SET_GUEST_ATTENDANCE, payload: { guestAttendance } });
    } catch (error) {
      // do nothing, thus hide feature
    }
  };

  return (
    <GuestContext.Provider
      value={{
        ...state,
        submitRsvpBody: submitRsvpBody || null,
        getGuestsForWedding,
        setGuestToContext,
        setGidCookie,
        getGuestById,
        updateGuestName,
        areNamesEmpty,
        resetState,
        addGuestsToParty,
        selectPartialMatch,
        updateHousehold,
        updateHouseholdAnswers,
        getGuestAttendanceList,
      }}
    >
      {props.children}
    </GuestContext.Provider>
  );
};
