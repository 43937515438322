import React, { useContext, useEffect, useRef } from "react";
import { Field as FormikField, Form, FormikProps } from "formik";
import { PreviewContext } from "../../Components/Context";
import get from "lodash/get";
import { FormField } from "../../Components";
import { IFormValues } from "./FormDefaults";
import ThemedButton from "../../ThemedButton";
import styled from "../../helpers/styled";

const ErrorMessage = styled("div")<{}>(
  {
    position: "relative",
    marginBottom: "30px",
    fontSize: "0.75rem", // 12px
  },
  ({ theme }) => ({ ...get(theme, "fonts.tiny", {}) })
);

type ErrorType = "submissionError" | "apiError" | string;

interface IProps extends FormikProps<IFormValues> {
  buttonText: string;
  onFieldChange?: () => void;
  noButton?: boolean;
  onBlur?: (values: IFormValues) => void;
  errorType?: ErrorType;
  errorMessage?: string;
}

const GuestSearchForm = ({
  isSubmitting,
  isValid,
  buttonText,
  noButton,
  handleBlur,
  onBlur,
  values,
  errorType,
  errorMessage,
  handleChange,
  onFieldChange,
  setFieldValue,
}: IProps) => {
  const firstNameRef = useRef<HTMLInputElement>(null);
  const { preview } = useContext(PreviewContext);
  const handleFieldChange = e => {
    handleChange(e);
    if (onFieldChange) {
      onFieldChange();
    }
  };

  useEffect(() => {
    if (firstNameRef.current) {
      firstNameRef.current.focus();
    }
  });

  const disabled = (isSubmitting || !isValid) && (!preview || values["Full Name"] !== "Test Guest");

  return (
    <Form data-testid="gs-form">
      <FormikField
        name="Full Name"
        ref={firstNameRef}
        component={FormField}
        onFocus={() => {
          if (preview && values["Full Name"] === "Test Guest") {
            setFieldValue("Full Name", "");
          }
        }}
        onBlur={e => {
          handleBlur(e);
          if (onBlur) {
            onBlur(values);
          }
        }}
        onChange={handleFieldChange}
      />
      {errorType && (
        <ErrorMessage data-testid="gs-submission-error">
          {errorType && errorType !== "duplicateError" && errorMessage}
        </ErrorMessage>
      )}
      {!noButton && <ThemedButton testId="guest-search-submit" type="submit" text={buttonText} disabled={disabled} />}
    </Form>
  );
};

GuestSearchForm.defaultProps = {
  buttonText: "Find Your Invitation",
};

export default GuestSearchForm;
