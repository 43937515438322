import React, { useCallback, useContext, useEffect } from "react";
import { IconButton } from "@xo-union/tk-component-icons";
import get from "lodash/get";

import ProgressBar from "./Components/ProgressBar";
import { AnalyticsContext } from "./Components/Context";
import { FlexCenter, isCustomDomain } from "./helpers";
import styled from "./helpers/styled";
interface IHeaderProps {
  slug: string;
  noTrack: boolean;
}

const Wrapper = styled("div")`
  ${FlexCenter}
`;

const StickyHeader = styled("div")`
  max-height: 4rem; /* 64px */
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: ${({ theme }) => get(theme, "colors.background")};
`;

const ProgressBarWrapper = styled("div")`
  padding: 10px 40px;
`;

const RsvpTitle = styled("h3")(
  {
    margin: "5.5px auto 0 auto",
    fontSize: "1.5rem", // 24px
  },
  ({ theme }) => ({
    ...get(theme, "fonts.s4", {}),
  })
);

const Icon = styled(IconButton)(
  {
    position: "absolute",
    top: "33%",
    transform: "translateY(-50%)",
    right: 0,
    marginRight: "20px",
    cursor: "pointer",
    "& svg": {
      strokeWidth: "2.5px",
    },
  },
  ({ theme }) => ({
    ...get(theme, "fonts.s6", {}),
    fontSize: "18px",
  })
);

const CenterText = styled("div")`
  display: flex;
`;

function Header({ slug, noTrack }: IHeaderProps) {
  const { track } = useContext(AnalyticsContext);

  const alertOnClose = useCallback((): boolean => {
    const routeReg = new RegExp(`/${slug}/rsvp/(.+)`, "i");
    const match = window.location.pathname.match(routeReg);
    const onConfirmation = window.location.pathname.match("/confirmation");
    if (!onConfirmation && match && match.length) {
      return true;
    }
    return false;
  }, [slug]);

  const navConfirmation = event => {
    if (alertOnClose()) {
      event.returnValue = "Are you sure? Your RSVP has not been sent.";
    }
  };

  useEffect(() => {
    if (!window["Cypress"]) {
      window.addEventListener("beforeunload", navConfirmation);
    }

    return () => window.removeEventListener("beforeunload", navConfirmation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = useCallback(() => {
    const query = window.location.search;
    !noTrack &&
      track({
        selection: "close rsvp initiated",
        rsvpPage: document.title,
      });
    if (alertOnClose()) {
      if (window.confirm("Are you sure? Your RSVP has not been sent.")) {
        !noTrack &&
          track({
            selection: "close rsvp",
            rsvpPage: document.title,
          });
        if (isCustomDomain(window.location.hostname)) {
          window.location.assign(`${window.location.origin}${query}`);
        } else {
          window.location.assign(`${process.env.REACT_APP_WWS_URL}/${slug}${query}`);
        }
        return;
      } else {
        !noTrack &&
          track({
            selection: "close rsvp cancelled",
            rsvpPage: document.title,
          });
      }
    } else {
      if (isCustomDomain(window.location.hostname)) {
        window.location.assign(`${window.location.origin}${query}`);
      } else {
        window.location.assign(`${process.env.REACT_APP_WWS_URL}/${slug}${query}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StickyHeader>
      <Wrapper>
        <CenterText>
          <RsvpTitle>RSVP</RsvpTitle>
        </CenterText>
        <Icon name="close" onClick={onClose} />
      </Wrapper>
      <ProgressBarWrapper>
        <ProgressBar />
      </ProgressBarWrapper>
    </StickyHeader>
  );
}

export default Header;
