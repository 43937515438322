import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import get from "lodash-es/get";

import {
  AnalyticsContext,
  Event,
  InvitationState,
  WeddingContext,
  IUpdateGuestRsvp,
  RouteContext,
} from "../../Components/Context";
import styled from "../../helpers/styled";
import { IconWrapper, Body, EventIcon } from "../../helpers";
import { EventAttire } from "./EventAttire";
import { EventDate } from "./EventDate";
import { EventLocation } from "./EventLocation";
import { EventNote } from "./EventNote";
import { EventRsvp } from "./EventRsvp";
import { EventTitle } from "./EventTitle";

export interface IEventsListProps {
  event: Event;
  invitations: InvitationState;
  updateGuestRsvp: IUpdateGuestRsvp;
}

const Wrapper = styled("div")`
  margin-bottom: 32px;
`;

export const EventsList = React.memo(function EventsListComponent({
  event,
  invitations,
  updateGuestRsvp,
}: IEventsListProps) {
  const { name, attire, date, time, id: eventId, location, notes, type } = event;
  const selectedEvent = invitations[event.id];
  const { track } = useContext(AnalyticsContext);
  const { wedding } = useContext(WeddingContext);
  const { nextRoute } = React.useContext(RouteContext);

  useEffect(() => {
    track({
      rsvpPage: "event response",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (selectedEvent && Object.keys(selectedEvent).length) {
    return (
      <div>
        <Wrapper>
          <EventTitle eventName={name} />
          {type === "WeddingDay" && get(wedding, ["hide_date"]) ? (
            <>
              <Body>
                <IconWrapper>
                  <EventIcon name="date" size="sm" />
                </IconWrapper>
                Date To Be Announced
              </Body>
            </>
          ) : (
            <EventDate date={date} time={time} />
          )}
          <EventLocation location={location} />
          <EventNote note={notes} />
          <EventAttire attire={attire} />
        </Wrapper>
        {Object.keys(selectedEvent)
          .reverse()
          .map((guestId, index) => {
            const guest = selectedEvent[guestId];
            if (guest) {
              let rsvpStatus = "";

              if (guest.rsvp === true) {
                rsvpStatus = "accept";
              } else if (guest.rsvp === false) {
                rsvpStatus = "reject";
              }

              return (
                <EventRsvp
                  key={`${guestId}-${eventId}`}
                  guestId={guestId}
                  value={rsvpStatus}
                  eventId={eventId}
                  onChange={updateGuestRsvp}
                  isLast={Object.keys(selectedEvent).length - 1 === index}
                />
              );
            }

            return null;
          })}
      </div>
    );
  }

  return <Redirect to={nextRoute} />;
});
