import * as Yup from "yup";

export interface IFormValues {
  "Full Name": string;
}

export const initialValues: IFormValues = {
  "Full Name": "",
};

export const formSchema = Yup.object().shape({
  "Full Name": Yup.string()
    .trim()
    .min(1, "Full Name must contain at least one character")
    .required("Full Name is required"),
});
