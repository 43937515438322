import React, { useContext } from "react";
import get from "lodash-es/get";

import { BannerText, Banner } from "../helpers/CSSHelpers";
import { WeddingContext } from "./Context";

const CovidBanner = () => {
  const { wedding } = useContext(WeddingContext);
  const message = get(wedding, ["announcement", "message"]);

  return (
    <Banner>
      <BannerText>{message}</BannerText>
    </Banner>
  );
};

export default CovidBanner;
