import { Question, Event } from "../Components/Context/EventContext";
import { determineStepsAndFactor } from "./progressFromURLAndEvents";

export const collectQuestionTypes = (mapping: Map<string, Question>) => {
  const types: string[] = [];
  mapping.forEach(val => {
    const { type: questionType } = val;
    if (types.indexOf(questionType) === -1) {
      types.push(questionType);
    }
  });
  return types;
};

// derive the maximum number of pages regardless of invitation responses
export const getMaxPageCount = (events: Event[], generalQuestions: Question[] = []) => {
  const { stepMap } = determineStepsAndFactor(events, generalQuestions);
  const pages = Object.keys(stepMap);
  const stepCount = pages.length;
  return stepCount;
};
