import { Global } from "@emotion/core";
import { compose } from "@xo-union/react-css-modules";
import { RadioButton, SingleSelectGroup } from "@xo-union/tk-component-switches";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTheme } from "emotion-theming";
import Alert from "@xo-union/tk-component-alerts";

import { AnalyticsContext, EventContext, GuestContext, WeddingContext } from "../../Components/Context";
import {
  Body,
  checkGuestNames,
  emptyInvitations,
  findPartyLeader,
  FullWidth,
  IHistoryProps,
  systemError,
  partyHasUnnamedGuest,
} from "../../helpers";
import ThemedButton from "../../ThemedButton";
import { determineBorderColor, determineBackgroundColor } from "../../helpers/analyzeControlColors";
import { PartialHouseholdMembers } from "./PartialHouseholdMembers";
import { PartialMatchHeading } from "./PartialMatchHeading";
import { SearchAgainButton } from "./SearchAgainButton";

export type ISelectedGuestType = "add_new" | string;

export function PartialMatch({ history, pushRoute }: IHistoryProps) {
  const [selectedGuest, setSelectedGuest] = useState<ISelectedGuestType>("");
  const [householdId, setHouseholdId] = useState("");
  const alertRef = useRef<any>(null);
  const { partialMatches, selectPartialMatch } = useContext(GuestContext);
  const { weddingSlug } = useContext(WeddingContext);
  const { eventBody } = useContext(EventContext);
  const { track } = useContext(AnalyticsContext);
  const handleChange = useCallback((values: React.FormEvent<HTMLInputElement>) => {
    const { value, dataset } = values.currentTarget;
    setSelectedGuest(value);
    setHouseholdId(dataset.uuid || "");
    track({
      selection: "select partial match",
      rsvpPage: "partial match",
      partialMatchCount: partialMatches && partialMatches.length,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = useTheme();

  const determineBackground = React.useCallback(() => {
    return determineBackgroundColor(theme);
  }, [theme]);

  const buttonBackground = determineBackground();

  const determineColor = React.useCallback(() => {
    return determineBorderColor(theme);
  }, [theme]);
  const buttonColor = determineColor();

  const handleNext = useCallback(async () => {
    track({
      selection: "confirm partial match",
      rsvpPage: "partial match",
    });
    if (selectedGuest !== "add_new") {
      try {
        const exactMatch = selectPartialMatch(householdId);
        if (eventBody && eventBody.isPrivateRsvp) {
          if (!exactMatch || emptyInvitations(exactMatch.people)) {
            alertRef.current.show({
              type: "error",
              content: "Oops! Something went wrong. Please try again or reach out to the couple.",
            });
          } else if (partyHasUnnamedGuest(exactMatch.people)) {
            pushRoute("edit-name");
          } else {
            pushRoute(checkGuestNames(exactMatch.people, weddingSlug));
          }
        } else if (eventBody && !eventBody.isPrivateRsvp) {
          if (exactMatch && partyHasUnnamedGuest(exactMatch.people)) {
            pushRoute("edit-name");
          } else {
            pushRoute("add-guests");
          }
        }
      } catch (error) {
        alertRef.current.show({
          type: "error",
          content: systemError,
        });
      }
    } else {
      pushRoute("add-guests");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGuest, householdId]);

  useEffect(() => {
    alertRef.current.dismiss();
  }, [selectedGuest, householdId]);

  const radioButtonClass = compose({
    "is-selected": "radio-is-selected",
    icon: "radio-icon",
  });

  return (
    <>
      <Global
        styles={{
          ".radio-icon": {
            zIndex: 1,
            position: "relative",
            bottom: "4px",
          },
          ".radio-is-selected .radio-icon": {
            border: `6px solid ${buttonColor} !important`,
            backgroundColor: `${buttonBackground}`,
          },
          ".partial-match-names": {
            maxWidth: "405px",
            display: "block",
            width: "75vw",
          },
        }}
      />
      <FullWidth>
        <Alert ref={alertRef} position="bottom" data-testid="partial-match-error" />
        <PartialMatchHeading numberOfMatches={partialMatches && partialMatches.length ? partialMatches.length : 0} />
        {partialMatches && partialMatches.length && (
          <SingleSelectGroup data-testid="partial-match-select" value={selectedGuest} onChange={handleChange}>
            {partialMatches.map(guest => {
              if (guest.people) {
                const leader = findPartyLeader(guest.people);

                if (leader) {
                  return (
                    <RadioButton
                      key={leader.id}
                      classes={radioButtonClass}
                      value={`${leader.firstName} ${leader.lastName}-${leader.id}`}
                      data-uuid={guest.id}
                    >
                      <Body>
                        <div className="partial-match-names">
                          {`${leader.firstName && leader.firstName.trim()} ${
                            leader.lastName ? leader.lastName.trim() : ""
                          }`}
                          <PartialHouseholdMembers persons={guest.people} />
                        </div>
                      </Body>
                    </RadioButton>
                  );
                }
              }

              // If the API returns [null] hide the guests
              return (
                <RadioButton classes={{ container: "display-none" }} value=" ">
                  {" "}
                </RadioButton>
              );
            })}
            <RadioButton
              classes={eventBody && eventBody.isPrivateRsvp ? { container: "display-none" } : radioButtonClass}
              value="add_new"
            >
              <Body>None of the above. Add me please!</Body>
            </RadioButton>
          </SingleSelectGroup>
        )}
        <ThemedButton
          testId="continueButton"
          onClick={handleNext}
          disabled={!selectedGuest}
          marginBottom={false}
          text="Continue"
        />
        <SearchAgainButton history={history} partialMatchCount={!partialMatches ? 0 : partialMatches.length} />
      </FullWidth>
    </>
  );
}
