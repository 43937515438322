import { Event } from "../Components/Context";

export const MEAL_TYPE = "Meal";

export function getMealCount(events: Event[]): number {
  return events.reduce((initial, event) => {
    const mealQuestion = event.questions?.find(q => q.type === MEAL_TYPE);
    if (mealQuestion) {
      return initial + 1;
    }

    return initial;
  }, 0);
}
