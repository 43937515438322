import { compact, toPairs } from "lodash";

import { InvitationState, Person, SubmitPerson } from "../Components/Context";

export function addInvitationResponsesToGuests(
  people: Person[],
  invitations: InvitationState,
  isPrivateRsvp?: boolean
): SubmitPerson[] {
  return compact<SubmitPerson>(
    people.map((guest): SubmitPerson | undefined => {
      const guestId = guest.id;
      const invitesArray = compact(
        toPairs(invitations).map(([_, response]) => {
          if (guestId && response[guestId]) {
            return response[guestId];
          }
          return undefined;
        })
      );

      if (!isPrivateRsvp && guest.isNew) {
        /*
         * Since client is creating id for it's reference
         * it needs to be removed from the call
         */
        const { isNew, id, email, phone, edited, ...rest } = guest;
        return { ...rest, invitations: invitesArray };
      }

      if (guest.invitations && guest.invitations.length) {
        const { email, phone, edited, ...rest } = guest;
        return { ...rest, invitations: invitesArray };
      }

      return undefined;
    })
  );
}
