import React from "react";

export function CheckMark() {
  return (
    <svg className="icon icon-has-no-size">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 32 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>checkmark</title>

        <path
          d="M13.1610153,27.381442 C12.9413389,27.3825705 12.7302473,27.2962149 12.5743487,27.141442 L3.587682,18.6881086 C3.42483982,18.525536 3.33333333,18.3048784 3.33333333,18.0747753 C3.33333333,17.8446722 3.42483982,17.6240146 3.587682,17.461442 C3.92920475,17.1295066 4.47282591,17.1295066 4.81434867,17.461442 L13.0943487,25.2481086 L27.4810153,5.68810862 C27.7645215,5.3015093 28.3077493,5.21793578 28.6943487,5.50144196 C29.080948,5.78494813 29.1645215,6.32817597 28.8810153,6.71477529 L13.867682,27.021442 C13.709583,27.2114048 13.4859494,27.3351169 13.2410153,27.3681086 L13.1610153,27.381442 Z"
          id="check"
        />
      </svg>
    </svg>
  );
}
