import get from "lodash-es/get";
import React, { useContext } from "react";

import { Title } from "../helpers";
import { WeddingContext } from "./Context";

export function WeddingName() {
  const { wedding } = useContext(WeddingContext);
  let weddingName = get(wedding, "wedding_name", "");

  if (weddingName) {
    weddingName = `${weddingName}'s Wedding`;
  } else {
    weddingName = "Wedding";
  }

  return <Title data-testid="wedding-name">{weddingName}</Title>;
}
