import { Formik, FormikActions, FormikProps } from "formik";
import honeybadger from "honeybadger-js";
import React, { useCallback, useContext, useState } from "react";

import {
  AnalyticsContext,
  EventContext,
  IGetGuestsForWedding,
  WeddingContext,
  PreviewContext,
  GuestContext,
} from "../../Components/Context";
import {
  checkGuestNames,
  emptyInvitations,
  IHistoryProps,
  systemError,
  partyHasUnnamedGuest,
  createTestGuest,
} from "../../helpers/";
import { allGuestsRespondedInParty } from "../../helpers/allGuestsRespondedInParty";
import { formSchema, IFormValues, initialValues as defaultInitialValues } from "./FormDefaults";
import GuestSearchForm from "./GuestSearchForm";

interface IErrors {
  submissionError: string;
  apiError: string;
  emptyInvitations: string;
}

type ErrorType = "submissionError" | "apiError" | string;

interface IProps extends IHistoryProps {
  getGuestsForWedding: IGetGuestsForWedding;
}

const previewInitialValues: IFormValues = {
  "Full Name": "Test Guest",
};

export function GuestSearchFormContainer({ pushRoute, getGuestsForWedding }: IProps) {
  const [errorType, setErrorType] = useState<ErrorType>("");
  const { weddingSlug, wedding } = useContext(WeddingContext);
  const { setGuestToContext } = useContext(GuestContext);
  const { preview } = useContext(PreviewContext);
  const { eventBody } = useContext(EventContext);
  const { track } = useContext(AnalyticsContext);
  const initialValues = preview ? previewInitialValues : defaultInitialValues;

  const errors: IErrors = {
    submissionError:
      "Oops! We’re having trouble finding your invite. Please try another spelling of your name or contact the couple",
    apiError: systemError,
    emptyInvitations: "Oops! Something went wrong. Please try again or reach out to the couple.",
  };

  const onFormSubmit = useCallback(async (values: IFormValues, actions: FormikActions<IFormValues>) => {
    const testGuest = preview && values["Full Name"] === "Test Guest";
    track({ action: "search invitations", selection: "rsvp", rsvpPage: "introduction" });
    try {
      let guest;
      if (testGuest) {
        guest = createTestGuest(eventBody?.events, wedding?.wedding_uuid);
        setGuestToContext(guest);
      } else {
        guest = await getGuestsForWedding({
          full_name: values["Full Name"].trim(),
        });
      }

      honeybadger.setContext({ guest, searchValues: values });

      if (guest.exactMatch) {
        if (!testGuest && partyHasUnnamedGuest(guest.exactMatch.people)) {
          return pushRoute("edit-name");
        }
        if (allGuestsRespondedInParty(guest.exactMatch.people)) {
          return pushRoute("confirmation", { completedRsvp: true });
        }
        if (emptyInvitations(guest.exactMatch.people)) {
          setErrorType("emptyInvitations");
          track({
            message: errors.emptyInvitations,
            source: "introduction",
            errorType: "empty invitations",
          });
        } else {
          const route =
            eventBody?.isPrivateRsvp === false ? "add-guests" : checkGuestNames(guest.exactMatch.people, weddingSlug);
          return pushRoute(route);
        }
      } else if (guest.partialMatches && guest.partialMatches.length) {
        return pushRoute("partial_match");
      } else if (eventBody && eventBody.isPrivateRsvp === false) {
        return pushRoute("add-guests");
      } else {
        setErrorType("submissionError");
        track({
          message: errors.submissionError,
          source: "introduction",
          errorType: "find invitation failure",
        });
        return;
      }
    } catch (error) {
      setErrorType("apiError");
      track({
        message: errors.apiError,
        source: "introduction",
        errorType: "find invitation failure due to API error",
      });
    }
    actions.setSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetErrors = useCallback((formProps: FormikProps<IFormValues>) => {
    formProps.setSubmitting(false);
    setErrorType("");
  }, []);

  return (
    <Formik initialValues={initialValues} onSubmit={onFormSubmit} validationSchema={formSchema}>
      {(formProps: FormikProps<IFormValues>) => (
        <>
          <GuestSearchForm
            {...formProps}
            onFieldChange={() => resetErrors(formProps)}
            errorType={errorType}
            errorMessage={errors[errorType]}
          />
        </>
      )}
    </Formik>
  );
}
