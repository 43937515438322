export interface ISplitFullName {
  firstName: string;
  lastName: string;
}
export function splitFullName(fullName: string): ISplitFullName {
  const splitted = fullName.split(" ");

  let firstName = "";
  let lastName = "";

  if (splitted.length === 2) {
    [firstName, lastName] = splitted;
  } else if (splitted.length < 2) {
    firstName = splitted[0];
  } else if (splitted.length > 2) {
    firstName = splitted[0];
    lastName = splitted.slice(1, splitted.length).join(" ");
  }

  return { firstName, lastName };
}
