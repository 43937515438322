import React from "react";
import * as Yup from "yup";
import { Formik, FormikActions, FormikProps, FormikValues } from "formik";
import honeybadger from "honeybadger-js";
import Cookie from "js-cookie";

import { GuestSearchService } from "../../Components/Context/GuestSearchService";
import {
  GuestContext,
  WeddingContext,
  PreviewContext,
  AnalyticsContext,
  RouteContext,
  MatchedSubmitBody,
  EventContext,
  InvitationContext,
} from "../../Components/Context";
import { findPartyLeader, systemError, hasMixedStatus } from "../../helpers";
import { GuestMessagingService, ScheduleBody } from "./GuestMessagingService";
import { IInitialValues, IOptInPref } from "./OptIn";
import { OptInForm } from "./OptInForm";
import ThemedButton from "../../ThemedButton";

interface OptInFormContainerProps {
  optIn: boolean;
  optInPref: IOptInPref;
  email: string;
  phone: string;
  leaderId: string;
  fireTrackEvent: (params: object) => void;
  showAlert: () => void;
  setOptInPref: (p: IOptInPref) => void;
  children: any;
}

export type IUpdates = { email?: string; phone?: string };

export interface IOptInPeople extends IUpdates {
  id: string;
  firstName: string;
}

export interface IOptInBody {
  weddingId: string;
  people: IOptInPeople[];
}

const removeEditedPropFromPeople = people => {
  let hasEditedGuest = false;
  people.forEach(person => {
    if (person.edited) {
      hasEditedGuest = true;
      delete person.edited;
    }
  });

  return hasEditedGuest;
};

const OptInFormContainer = (props: OptInFormContainerProps) => {
  const { optIn, optInPref, email, phone, fireTrackEvent, showAlert, children } = props;
  const { wedding, weddingUuid } = React.useContext(WeddingContext);
  const { preview } = React.useContext(PreviewContext);
  const { exactMatch, updateHousehold, submitRsvpBody: responseBody } = React.useContext(GuestContext);
  const { eventBody } = React.useContext(EventContext);
  const { track } = React.useContext(AnalyticsContext);
  const { pushNextRoute } = React.useContext(RouteContext);
  const { invitations } = React.useContext(InvitationContext);

  const optInInitialValues: IInitialValues = {
    Email: !email ? "" : email,
    Phone: !phone ? "" : phone,
  };

  function validateValues(values: FormikValues) {
    let errors = {};
    const emailSchema = Yup.string().email();

    if (optIn) {
      if (optInPref === "email" || optInPref === "international") {
        if (!values.Email) {
          errors["Email"] = "Email is required";
        }
        if (values.Email && values.Email !== email) {
          if (!emailSchema.isValidSync(values.Email)) {
            errors["Email"] = "Invalid email entered";
          }
        }
      }
    }

    return errors;
  }

  const fireTrackSendEvent = () => {
    if (!responseBody) return;
    const rsvpMixedStatus = hasMixedStatus(invitations);
    const hasEditedGuest = removeEditedPropFromPeople(responseBody.people);

    const analyticsObject = {
      selection: "send RSVP",
      hasPlusOne: hasEditedGuest,
      editedGuest: hasEditedGuest,
      rsvpMixedStatus,
    };

    track(analyticsObject);
  };

  const addContactInfo = (values: IInitialValues) => {
    let updates: IUpdates = {};
    if (values.Email !== email) {
      updates.email = values.Email;
    }

    if (values.Phone !== phone) {
      updates.phone = values.Phone;
    }

    if (Object.keys(updates).length) {
      const people = responseBody?.people || [];
      // find the leader or assume new household so first person is leader
      const leader = people.find(person => person.isLeader) || people[0];

      if (leader) {
        const withoutLeader = people.filter((_, i) => i !== people.indexOf(leader));
        return { people: [{ ...leader, ...updates }, ...withoutLeader] };
      }
    }

    return {};
  };

  const requestSend = async (values: IInitialValues) => {
    if (!responseBody) return;

    const allowsAddGuests = eventBody && !eventBody.isPrivateRsvp;
    // exactMatch will contains answers if there are questions so checking for household id
    const householdNotFound = !exactMatch || !exactMatch.id;
    const noExisting = wedding && householdNotFound && allowsAddGuests;

    // Do a POST to create a new guest
    // Do a PUT if the guest is found
    const type: "post" | "put" = noExisting ? "post" : "put";

    let submitResponseBody = { ...responseBody };
    if (optIn) {
      submitResponseBody = { ...responseBody, ...addContactInfo(values) };
    }

    let response;
    try {
      if (type === "put") {
        response = await GuestSearchService.updateGuestResponse<MatchedSubmitBody>({
          body: submitResponseBody,
          weddingUuid,
          householdId: exactMatch ? exactMatch.id : "",
          memberUuid: wedding ? wedding.user_uuid : "",
        });
      } else if (type === "post") {
        response = await GuestSearchService.createNewGuest({
          body: submitResponseBody,
          weddingUuid,
          memberUuid: wedding ? wedding.user_uuid : "",
        });
      }
      fireTrackSendEvent();
    } catch (error) {
      honeybadger.notify(error, {
        name: "Guest Update API",
        message: "Submit RSVP failed",
        context: {
          params: responseBody,
          apiResponse: error,
        },
      });
      showAlert && showAlert();
      track(
        {
          message: systemError,
          errorType: "cannot submit rsvp",
        },
        "Error Message Displayed"
      );
    }
    return response;
  };

  async function createRsvp(values: IInitialValues) {
    const response = await requestSend(values);
    const { data } = response || {};

    if (data) {
      updateHousehold(data);
      const leader = findPartyLeader(data.people);

      if (leader && leader.id) {
        Cookie.set("gid", leader.id);
      }
      return data;
    } else {
      throw response;
    }
  }

  async function submitRSVPConfirmation(values: IInitialValues, response) {
    fireTrackEvent({
      selection: "confirm optin",
      reminderType: "email",
      reminderSelection: optIn,
    });

    const member_id = wedding ? wedding.user_uuid : "";
    const wedding_id = wedding ? wedding.wedding_uuid : "";
    const leader = response.people.find(person => person.isLeader);
    const guest_id = leader ? leader.id : "";

    const scheduleBody: ScheduleBody = {
      member_id,
      sequence_id: process.env.REACT_APP_SCHEDULE_GM_SEQ_ID,
      guest_ids: [guest_id],
      preference: "email",
      wedding_id: wedding_id,
    };

    try {
      await GuestMessagingService.scheduleGuestMessage(scheduleBody);
    } catch (error) {
      honeybadger.notify(error, {
        name: "Preference/Schedule Message API",
        message: "Failed to schedule email or set preferences",
        context: {
          params: {
            scheduleBody,
            values,
          },
          apiResponse: error,
        },
      });
    }
  }

  const handleSubmit = async (values: IInitialValues, formikActions: FormikActions<IInitialValues>) => {
    try {
      const response = await createRsvp(values);
      if (optIn) await submitRSVPConfirmation(values, response);
      pushNextRoute();
    } catch (error) {
      showAlert();
    }
    formikActions.setSubmitting(false);
  };

  return (
    <Formik initialValues={optInInitialValues} validate={validateValues} onSubmit={handleSubmit}>
      {(props: FormikProps<IInitialValues>) => (
        <>
          {optIn && (
            <OptInForm optInPref={optInPref} fireTrackEvent={fireTrackEvent} {...props}>
              {children}
            </OptInForm>
          )}
          <ThemedButton
            color="primary"
            type="submit"
            text="Send RSVP"
            disabled={props.isSubmitting}
            onClick={() => {
              if (preview) {
                pushNextRoute();
              } else {
                props.submitForm();
              }
            }}
          />
        </>
      )}
    </Formik>
  );
};

export default OptInFormContainer;
