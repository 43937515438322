import { Person } from "../Components/Context";

export function emptyInvitations(people: Person[]): boolean {
  const guests = people.filter(guest => !guest.invitations || guest.invitations.length === 0);

  if (guests.length === people.length) {
    return true;
  }

  return false;
}
