import { InvitationState } from "../Components/Context/InvitationContext";

export function allGuestsResponded(invitations: InvitationState, eventId: string): boolean {
  const event = invitations[eventId];
  return Object.keys(event).reduce<boolean>((initialVal, guestKey) => {
    const guest = event[guestKey];

    if (initialVal === false) {
      return false;
    }

    if (guest.rsvp === null) {
      return false;
    }

    return true;
  }, true);
}
