import React, { useContext } from "react";

import { WeddingContext } from "./Context/WeddingContext";

export function CoupleNames() {
  const { wedding } = useContext(WeddingContext);

  let coupleName = "";

  if (wedding && wedding.fiance_first_name && wedding.first_name) {
    coupleName = `${wedding.first_name} & ${wedding.fiance_first_name}'s`;
  }

  return <>{coupleName}</>;
}
