import React from "react";
import get from "lodash-es/get";
import { IAcceptRejectButtonProps } from "../../helpers";
import styled from "../../helpers/styled";
import Icon from "@xo-union/tk-component-icons";

type IRsvpType = "accept" | "reject";

const BaseButton = styled("button")`
  height: 40px;
  width: 124px;
  font-size: 16px;
  border-radius: 2px;
  @media (max-width: 480px) {
    width: 90px;
    height: 34px;
    font-size: 14px;
  }
`;

const ResponseIcon = styled(Icon)`
  position: relative;
  top: 3px;
  vertical-align: baseline;
  @media (max-width: 480px) {
    display: none;
  }
`;

const ResponseText = styled("span")`
  padding-left: 5px;
  font-weight: ${({ theme }) => get(theme, ["fonts", "s6", "fontWeight"]) || "normal"};
  font-family: ${({ theme }) => get(theme, ["fonts", "s6", "fontFamily"]) || ""};
`;

export const ResponseButton = styled(BaseButton)<IAcceptRejectButtonProps>`
  background-color: ${({ theme, selected }) =>
    !selected ? get(theme, ["colors", "background"]) || "white" : get(theme, ["colors", "primary"]) || "black"};
  border: 2px solid ${({ theme }) => get(theme, ["colors", "primary"]) || "black"};
  color: ${({ theme, selected }) =>
    !selected ? get(theme, ["colors", "primary"]) || "black" : get(theme, ["colors", "background"]) || "white"};
  opacity: ${({ noResponse, selected }) => (!noResponse && !selected ? 0.5 : 1)};
`;

export interface IRsvpButton {
  type: IRsvpType;
  selection: string;
  onClick?: () => void;
}

export function RsvpButton({ type, onClick, selection }: IRsvpButton) {
  const noResponse = !selection;
  if (type === "accept") {
    const acceptSelected = selection === "accept";
    return (
      <ResponseButton data-testid="accept" onClick={onClick} selected={acceptSelected} noResponse={noResponse}>
        {acceptSelected && <ResponseIcon size="sm" name="checkmark_circle" />}
        <ResponseText>{acceptSelected ? "Accepted" : "Accept"}</ResponseText>
      </ResponseButton>
    );
  } else if (type === "reject") {
    const rejectSelected = selection === "reject";
    return (
      <ResponseButton data-testid="reject" onClick={onClick} selected={rejectSelected} noResponse={noResponse}>
        {rejectSelected && <ResponseIcon size="sm" name="close_circle" />}
        <ResponseText>{rejectSelected ? "Declined" : "Decline"}</ResponseText>
      </ResponseButton>
    );
  }

  return null;
}
