import { Person } from "../Components/Context";

export function areNamesEmpty(partyGuest: Person[]): boolean {
  return partyGuest.reduce<boolean>((initialVal, guest) => {
    if (initialVal === true) {
      return true;
    }
    const fullName = `${guest.firstName}${guest.lastName}`;
    if (fullName.length === 0) {
      return true;
    }

    return false;
  }, false);
}
