import get from "lodash/get";

function hexToRGBArray(color) {
  color = color.replace("#", "");
  if (color.length === 3)
    color = color.charAt(0) + color.charAt(0) + color.charAt(1) + color.charAt(1) + color.charAt(2) + color.charAt(2);
  else if (color.length !== 6) return 0;
  const rgb: number[] = [];
  for (var i = 0; i <= 2; i++) rgb[i] = parseInt(color.substr(i * 2, 2), 16);
  return rgb;
}
function luma(color) {
  // color can be a hx string or an array of RGB values 0-255
  var rgb = typeof color === "string" ? hexToRGBArray(color) : color;
  return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]; // SMPTE C, Rec. 709 weightings
}

export const determineBorderColor = (theme): String => {
  const fallbackColor = "#3ea9da";

  let color = get(theme, "colors.primaryHover");
  if (!color) {
    color = get(theme, "colors.primary");
    if (!color) {
      color = fallbackColor;
    }
  }
  return color;
};

export const determineBackgroundColor = (theme): String => {
  const fallbackColor = "#000000";
  let border = get(theme, "colors.primaryHover");
  if (!border) {
    border = get(theme, "colors.primary");
    if (!border) {
      border = fallbackColor;
    }
  }
  // check if border color is too similar to white,
  // if it is, pass back theme background
  return luma(border) >= 165 ? get(theme, "colors.background") : "#ffffff";
};
