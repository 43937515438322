import React, { useContext } from "react";
import get from "lodash-es/get";

import Header from "./Header";
import { WeddingContext } from "./Components/Context";
import CovidBanner from "./Components/CovidBanner";
import styled from "./helpers/styled";
import { FlexCenter, IHistoryPropsNoPush } from "./helpers";

export const ChildWrapper = styled("div")`
  width: 100%;
  flex-wrap: wrap;
  ${FlexCenter}
`;

interface LayoutProps extends IHistoryPropsNoPush {
  noTrack: boolean;
}

export function Layout({ children, match, noTrack = true }: LayoutProps) {
  const { wedding } = useContext(WeddingContext);

  return (
    <>
      {get(wedding, ["announcement", "show"]) && <CovidBanner />}
      <Header slug={match.params.weddingSlug || ""} noTrack={noTrack} />
      <ChildWrapper>{children}</ChildWrapper>
    </>
  );
}
