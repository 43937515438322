import { Event } from "../Components/Context";
import { InvitationState } from "../Components/Context/InvitationContext";

export type IFrom = "events" | "meals" | "questions";

// determine next /event url
export function getNextEventUrl(
  currentIndex: number,
  events: Event[],
  invitations: InvitationState,
  from: IFrom
): string {
  const event: Event = events[currentIndex];

  if (event) {
    const nextEvent = events[currentIndex + 1];
    if (nextEvent) {
      const hasGuests = Object.keys(invitations[nextEvent.id]).length;
      if (hasGuests) {
        return `events/${currentIndex + 1}`;
      }
      return getNextEventUrl(currentIndex + 1, events, invitations, from);
    } else {
      return `note`;
    }
  } else {
    return `note`;
  }
}
