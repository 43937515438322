import React from "react";

export function Close() {
  return (
    <svg className="icon icon-has-no-size">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 32 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>close-gray</title>

        <path
          d="M17.2790034,16.0002755 L27.7357545,5.54057059 C28.0880818,5.18814375 28.0880818,4.61674696 27.7357545,4.26432013 C27.3834272,3.91189329 26.8121918,3.91189329 26.4598645,4.26432012 L15.989245,14.7240251 L5.54636223,4.26432012 C5.19113493,3.91896745 4.62569947,3.91896745 4.27047218,4.26432012 C4.09760493,4.43158707 4,4.66186646 4,4.90244535 C4,5.14302424 4.09760493,5.37330364 4.27047218,5.54057059 L14.7272233,16.0002755 L4.27047218,26.4599805 C4.09760493,26.6272475 4,26.8575269 4,27.0981057 C4,27.3386846 4.09760493,27.568964 4.27047218,27.736231 C4.43698779,27.9093539 4.66830083,28.0049232 4.9084172,27.9998044 C5.14760215,28.0000846 5.37709294,27.9052681 5.54636223,27.736231 L15.989245,17.276526 L26.4459961,27.736231 C26.6125117,27.9093539 26.8438248,28.0049232 27.0839411,27.9998044 C27.3231261,28.0000846 27.5526169,27.9052681 27.7218862,27.736231 C28.0671413,27.3809033 28.0671413,26.8153082 27.7218862,26.4599805 L17.2790034,16.0002755 Z"
          id="close"
        />
      </svg>
    </svg>
  );
}
