import React from "react";

import { RouteContext, Question } from "../../Components/Context";
import ThemedButton from "../../ThemedButton";
import TextBoxField from "../../Components/TextBoxField";

type TextAnswer = { questionId: string; text: string };

interface IQShortProps {
  question: Question;
  findPreviousAnswer: () => string;
  updateAnswer: (answer: TextAnswer) => void;
}

function ShortAnswer(props: IQShortProps) {
  const { question, findPreviousAnswer, updateAnswer } = props;
  const { pushNextRoute } = React.useContext(RouteContext);

  const previousAnswer = findPreviousAnswer();
  const [textAnswer, setTextAnswer] = React.useState(previousAnswer);

  const clearTextAnswer = () => setTextAnswer("");
  const setPreviousTextAnswer = React.useCallback(() => setTextAnswer(previousAnswer), [previousAnswer]);

  React.useEffect(() => {
    if (question) {
      setPreviousTextAnswer();
    }
  }, [question, setPreviousTextAnswer]);

  const constructUpdateAnswer = () => {
    if (textAnswer.trim()) {
      const answer = { questionId: question.id, text: textAnswer.trim() };
      updateAnswer(answer);
    }
  };

  return (
    <>
      <TextBoxField
        name="ShortAnswer"
        value={textAnswer}
        onFocus={() => {}}
        onChange={value => setTextAnswer(value)}
        label={false}
      />
      <div>
        <ThemedButton
          text="Continue"
          testId="Button-Continue"
          disabled={false}
          onClick={() => {
            constructUpdateAnswer();
            pushNextRoute();
            clearTextAnswer();
          }}
        />
        <ThemedButton
          text="Skip"
          testId="Button-Skip"
          disabled={false}
          onClick={() => {
            pushNextRoute();
            clearTextAnswer();
          }}
        />
      </div>
    </>
  );
}

export default ShortAnswer;
