import React, { useEffect, useReducer } from "react";
import { Location } from "history";

export interface IPreviewContextState {
  preview: boolean;
  previewTheme: number | null;
}

interface PreviewProviderProps {
  children: any;
  location: Location;
}

const initialState = {
  preview: false,
  previewTheme: null,
};

export const PreviewContext = React.createContext<IPreviewContextState>(initialState);

export const PreviewConsumer = PreviewContext.Consumer;

type PreviewActions = { type: "SET_PREVIEW_STATE"; payload: IPreviewContextState };

function reducer(state: IPreviewContextState, action: PreviewActions) {
  switch (action.type) {
    case "SET_PREVIEW_STATE": {
      return { ...state, ...action.payload };
    }
    default:
      return { ...state };
  }
}

export function PreviewProvider(props: PreviewProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const params = new URLSearchParams(props.location.search);
  const preview = params.get("preview");
  const themeId = params.get("themeId");

  useEffect(() => {
    dispatch({
      type: "SET_PREVIEW_STATE",
      payload: {
        preview: preview === "true" ? true : false,
        previewTheme: themeId ? +themeId : null,
      },
    });
  }, [preview, themeId]);

  return <PreviewContext.Provider value={state}>{props.children}</PreviewContext.Provider>;
}
