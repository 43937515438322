import { Global } from "@emotion/core";
import { compose } from "@xo-union/react-css-modules";
import Alert from "@xo-union/tk-component-alerts";
import { Checkbox } from "@xo-union/tk-component-switches";
import get from "lodash/get";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTheme } from "emotion-theming";

import { AnalyticsContext, GuestContext, WeddingContext } from "../../Components/Context";
import {
  Body as ImportedBody,
  findPartyLeader,
  FullWidth,
  IHistoryProps,
  systemError,
  Link,
  determineOptInDetails,
} from "../../helpers";

import { requestCountryCode } from "../../helpers/requestCountryCode";
import styled from "../../helpers/styled";
import { determineBorderColor, determineBackgroundColor } from "../../helpers/analyzeControlColors";
import { OptInHeading } from "./OptInHeading";
import OptInFormContainer from "./OptInFormContainer";
import { GuestMessagingService, PreferenceResponse } from "./GuestMessagingService";

export type IOptInPref = "phone" | "email" | "international";

export interface OptInInputProps {
  value: string;
  onChange: (value) => void;
}

const Body = styled(ImportedBody)`
  margin-bottom: 0;
`;

const Anchor = styled(Link)`
  margin-top: "20px",
  margin-bottom: "50px",
`;

export interface IInitialValues {
  Email: string;
  Phone: string;
}

export type IDefaultContact = "email" | "phone" | "both" | "none";

const checkBoxClass = compose({
  label: "optin-checkbox-label",
  "checkmark-container": "optin-checkbox-container",
  "is-selected": "optin-is-selected",
  "checkmark-border": "optin-checkmark-border",
});

export function OptIn(props: IHistoryProps): JSX.Element {
  const [optIn, setOptIn] = useState(true);
  const alertRef = useRef<any>(null);
  const { wedding } = useContext(WeddingContext);
  const { people } = useContext(GuestContext);
  const { track } = useContext(AnalyticsContext);
  const rsvpPage = "messaging optins";

  const leader = findPartyLeader(people);
  const email = get(leader, "email", "");
  const phone = get(leader, "phone", "");

  const [optInPref, setOptInPref] = useState<IOptInPref>(email && !phone ? "email" : "phone");
  const [existingPreferences, setExistingPreferences] = useState({} as PreferenceResponse);
  const [optInDetails, setOptInDetails] = useState("");

  useEffect(() => {
    async function fetchCountryCode() {
      const countryCode = await requestCountryCode();
      if (countryCode && countryCode !== "USA") {
        setOptIn(false);
        setOptInPref("international");
      }
    }
    fetchCountryCode();
  }, []);

  useEffect(() => {
    async function fetchExistingPreferences(weddingID, personID) {
      // If this is public RSVP, we might not have a personID yet,
      // so there won't be any preferences to lookup.
      if (!personID || personID === "") return;
      const { data: existingPreferencesResponse } = await GuestMessagingService.fetchExistingPreferences(
        weddingID,
        personID
      );

      setExistingPreferences(existingPreferencesResponse);

      // After fetching the preferences, select the preference they are not opted out of
      // We supply "phone" as the selected preference, so that if the guest has phone selected by default,
      // and are globally opted out of SMS, that warning will display.
      // Otherwise, the preferences are respected, and what their default selection is doesn't matter.
      const { newPrefToSelect, optedOutPref, optInDetails: details } = determineOptInDetails(
        existingPreferencesResponse,
        wedding?.wedding_name || "",
        "phone"
      );

      if (newPrefToSelect !== "") {
        setOptInPref(newPrefToSelect as IOptInPref);
      }

      // Set the optInDetails based on the new preference to select, and their opted out preference
      if ((optedOutPref === "both" || optedOutPref === newPrefToSelect) && details !== "") {
        setOptInDetails(details);
      } else {
        setOptInDetails("");
      }
    }
    fetchExistingPreferences(wedding?.wedding_uuid, leader?.id);
  }, [wedding, leader]);

  useEffect(() => {
    const { optInDetails: details, optedOutPref } = determineOptInDetails(
      existingPreferences,
      wedding?.wedding_name || "",
      optInPref
    );
    if ((optedOutPref === "both" || optedOutPref === optInPref) && details !== "") {
      setOptInDetails(details);
    } else {
      setOptInDetails("");
    }
  }, [optInPref, existingPreferences, wedding]);

  const defaultContact: IDefaultContact = React.useMemo(() => {
    if (email && phone) {
      return "both";
    } else if (email) {
      return "email";
    } else if (phone) {
      return "phone";
    }
    return "none";
  }, [email, phone]);

  useEffect(() => {
    track({
      rsvpPage,
      defaultContact,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showAlert = React.useCallback(() => {
    alertRef.current.show({
      type: "error",
      content: systemError,
    });
  }, []);

  const theme = useTheme();

  const determineBackground = React.useCallback(() => {
    return determineBackgroundColor(theme);
  }, [theme]);

  const buttonBackground = determineBackground();

  const determineColor = React.useCallback(() => {
    return determineBorderColor(theme);
  }, [theme]);
  const buttonColor = determineColor();

  const weddingName = wedding && wedding.wedding_name ? `${wedding.wedding_name}'s Wedding` : "the Wedding";

  const fireTrackEvent = (additionalParams = {}) => {
    track({
      rsvpPage,
      defaultContact,
      ...additionalParams,
    });
  };

  return (
    <>
      <Global
        styles={{
          ".radio-icon": {
            position: "relative",
            bottom: "1px",
          },
          ".optin-radio-container": {
            flex: "0.75",
            zIndex: 1,
          },
          ".optin-checkbox-label": {
            alignItems: "flex-start",
          },
          ".optin-checkbox-container": {
            marginTop: 0,
          },
          ".optin-is-selected .optin-checkmark-border": {
            border: `12px solid ${buttonColor} !important`,
          },
          ".optin-is-selected .optin-checkmark-border svg": {
            color: `${buttonBackground}`,
          },
          ".radio-is-selected .radio-icon": {
            border: `6px solid ${buttonColor} !important`,
            backgroundColor: `${buttonBackground}`,
          },
        }}
      />
      <FullWidth>
        <Alert ref={alertRef} position="bottom" />
        <OptInHeading weddingName={weddingName} />
        <Checkbox
          data-testid="OptIn-Checkbox"
          checked={optIn}
          aria-checked={optIn}
          classes={checkBoxClass}
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
            track({
              selection: target.checked ? "select optin" : "deselect optin",
              rsvpPage,
              defaultContact,
            });
            setOptIn(target.checked);
          }}
        >
          <Body>Send me an RSVP confirmation by email</Body>
        </Checkbox>

        <OptInFormContainer
          optIn={optIn}
          optInPref={"email"}
          fireTrackEvent={fireTrackEvent}
          email={email || ""}
          phone={phone || ""}
          showAlert={showAlert}
          leaderId={leader?.id || ""}
          setOptInPref={setOptInPref}
        >
          <Body>{optInDetails}</Body>
        </OptInFormContainer>
        <Anchor target="_blank" href="https://www.theknot.com/privacy-policy">
          View Our Privacy Policy
        </Anchor>
      </FullWidth>
    </>
  );
}
