import "react-app-polyfill/ie11";
import "@xo-union/tk-ui-essentials";

import ErrorBoundary from "@honeybadger-io/react";
import Honeybadger from "honeybadger-js";
import React from "react";
import ReactDOM from "react-dom";

import { loadPolyfills } from "./polyfill";
import App from "./App";

const { REACT_APP_HONEYBADGER_API_KEY, REACT_APP_ENV, GIT_REVISION } = process.env;

const config: any = {
  api_key: REACT_APP_HONEYBADGER_API_KEY || prompt("Missing honeybadger API key"),
  environment: REACT_APP_ENV,
  revision: GIT_REVISION,
  disabled: REACT_APP_ENV === "development" || REACT_APP_ENV === "test",
};

const honeybadger = Honeybadger.configure(config);

loadPolyfills();

ReactDOM.render(
  <ErrorBoundary honeybadger={honeybadger}>
    <App />
  </ErrorBoundary>,
  document.getElementById("root")
);
