import { Person } from "../Components/Context";

export function allGuestsRespondedInParty(partyGuests: Person[]): boolean {
  return partyGuests.reduce<boolean>((initialVal, guest) => {
    if (initialVal === false) {
      return false;
    }

    if (!guest.invitations || !guest.invitations.length) {
      return false;
    }
    return guest.invitations.reduce<boolean>((initial, invite) => {
      if (initial === false) {
        return false;
      }
      if (invite.rsvp === null) {
        return false;
      }

      return true;
    }, true);
  }, true);
}
