import React from "react";
import get from "lodash-es/get";
import { IRsvpIconProp, RoundButton } from "../../helpers";
import styled from "../../helpers/styled";
import { CheckMark } from "./CheckMark";
import { Close } from "./Close";

type IRsvpType = "accept" | "reject";

export const AcceptRejectIcon = styled(RoundButton)<IRsvpIconProp>`
  background-color: ${({ theme, isSelected }) =>
    isSelected ? get(theme, ["colors", "primary"]) || "black" : get(theme, ["colors", "background"]) || "white"};
  border: 1px solid ${({ theme }) => get(theme, ["colors", "primary"]) || "black"};
  color: ${({ theme, isSelected }) =>
    isSelected ? get(theme, ["colors", "background"]) || "white" : get(theme, ["colors", "primary"]) || "black"};
`;

export const AcceptButton = styled(RoundButton)<IRsvpIconProp>`
  background-color: ${({ theme, isSelected }) => (isSelected ? get(theme, ["colors", "primaryHover"]) : theme.white)};
  border: 1px solid
    ${({ theme, isSelected }) => (isSelected ? get(theme, ["colors", "primaryHover"]) : theme["gray-7"])};
  color: ${({ theme, isSelected }) => (isSelected ? get(theme, ["colors", "background"]) : theme["gray-7"])};
`;

export const RejectButton = styled(RoundButton)<IRsvpIconProp>`
  background-color: ${({ theme, isSelected }) => (isSelected ? theme["gray-5"] : theme.white)};
  border: 1px solid ${({ theme, isSelected }) => (isSelected ? theme["gray-5"] : theme["gray-7"])};
  color: ${({ theme, isSelected }) => (isSelected ? theme.white : theme["gray-7"])};
`;

export interface IRsvpIcon {
  type: IRsvpType;
  isSelected: boolean;
  onClick?: () => void;
  small?: boolean;
}

export function RsvpIcon({ type, onClick, isSelected, small = false }: IRsvpIcon) {
  if (type === "accept") {
    return (
      <AcceptRejectIcon data-testid="accept" onClick={onClick} isSelected={isSelected} small={small}>
        <CheckMark />
      </AcceptRejectIcon>
    );
  } else if (type === "reject") {
    return (
      <AcceptRejectIcon data-testid="reject" onClick={onClick} isSelected={isSelected} small={small}>
        <Close />
      </AcceptRejectIcon>
    );
  }

  return null;
}
