export function isCustomDomain(host: string): boolean {
  if (
    host === "theknot.com" ||
    host.endsWith(".theknot.com") ||
    host.endsWith("localhost") ||
    host.endsWith("xogrp.com")
  ) {
    return false;
  }
  return true;
}
