import React from "react";

import { Person } from "../../Components/Context";

export interface PartialHouseholdMembersProps {
  persons: Person[];
}

export function PartialHouseholdMembers({ persons = [] }: PartialHouseholdMembersProps) {
  const guestNames = persons.filter(person => !person.hasOwnProperty("isLeader") || !person.isLeader);

  if (guestNames.length) {
    return (
      <>
        {guestNames.map((guest, index) => (
          <span key={guest.id}>
            {index === guestNames.length - 1 ? " & " : ", "}
            {`${guest.firstName && guest.firstName.trim()} ${guest.lastName ? guest.lastName.trim() : ""}`}
          </span>
        ))}
      </>
    );
  }

  return null;
}
