import React from "react";

import { RouteContext, Question } from "../../Components/Context";
import ThemedButton from "../../ThemedButton";
import SelectButton from "../../Components/SelectButton";

type OptionAnswer = { optionId: string; questionId: string };

interface IQChoiceProps {
  question: Question;
  findPreviousAnswer: () => string | undefined;
  updateAnswer: (answer: OptionAnswer) => void;
  keyPrefix: string;
}

const Select = (props: IQChoiceProps) => {
  const { question, keyPrefix, findPreviousAnswer, updateAnswer } = props;
  const previousAnswer = React.useMemo(() => findPreviousAnswer(), [findPreviousAnswer]);

  const { pushNextRoute } = React.useContext(RouteContext);
  const [selected, setSelected] = React.useState({ id: previousAnswer });

  const clearSelected = () => setSelected({ id: undefined });
  const setPreviousSelected = React.useCallback(() => setSelected({ id: previousAnswer }), [previousAnswer]);

  React.useEffect(() => {
    if (question) {
      setPreviousSelected();
    }
  }, [question, setPreviousSelected]);

  const { options } = question;

  const constructUpdateAnswer = option => {
    const answer = { optionId: option.id, questionId: question.id };
    updateAnswer(answer);
  };

  const handleOnClick = option => {
    constructUpdateAnswer(option);
    setSelected(option);
  };

  return (
    <>
      {options?.map(opt => (
        <SelectButton
          key={`${keyPrefix}-${opt.id}`}
          testId={`Select-${opt.text}`}
          text={opt.text}
          subText={opt.description || undefined}
          onClick={() => handleOnClick(opt)}
          selected={selected.id === opt.id}
        />
      ))}
      <ThemedButton
        text="Continue"
        testId="Button-Continue"
        disabled={!selected.id}
        onClick={() => {
          clearSelected();
          pushNextRoute();
        }}
      />
    </>
  );
};

export default Select;
