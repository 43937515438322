import React from "react";
import { Redirect } from "react-router-dom";

import {
  EventContext,
  GuestContext,
  InvitationContext,
  RouteContext,
  AnalyticsContext,
  Question,
  Event,
  Person,
} from "../../Components/Context";
import { FullWidth, Title, GuestName as BaseGuestName } from "../../helpers";
import styled from "../../helpers/styled";
import { IGuestResponseProps } from "../GuestResponse/GuestResponse";
import Select from "./Select";
import ShortAnswer from "./ShortAnswer";

const GuestName = styled(BaseGuestName)`
  margin-bottom: 1.25rem;
`;

const OPTION_TYPE = "Option";
const TEXT_TYPE = "Text";

interface ConfirmedProps {
  event: Event;
  question: Question;
  guest: Person;
}

export const EventQuestionPage = (props: IGuestResponseProps) => {
  const { getGuestById } = React.useContext(GuestContext);
  const { events } = React.useContext(EventContext);
  const { getAcceptedGuests } = React.useContext(InvitationContext);

  const { eventIndex, questionIndex, guestIndex, nextRoute } = React.useContext(RouteContext);

  const redirectToNext = () => {
    return <Redirect to={nextRoute} />;
  };

  // safety check for indecies
  if ((!eventIndex && eventIndex !== 0) || (!questionIndex && questionIndex !== 0)) {
    return redirectToNext();
  }

  const event = events[eventIndex];

  // redirect if no questions
  if (!event.questions || !event.questions.length) {
    return redirectToNext();
  }

  const question = event.questions[questionIndex];
  const guests = getAcceptedGuests(event.id, getGuestById);

  // redirect if no guests so no need to ask question
  if (!guests.length) {
    return redirectToNext();
  }

  const invalidQuestionType = question.answerType !== OPTION_TYPE && question.answerType !== TEXT_TYPE;
  const invalidNoOptions = question.answerType === OPTION_TYPE && !question?.options?.length;

  if (invalidQuestionType || invalidNoOptions) {
    return redirectToNext();
  }

  const guest = guests[guestIndex || 0];

  return <QuestionConfirmedPage {...props} event={event} question={question} guest={guest} />;
};

const QuestionConfirmedPage = (props: ConfirmedProps) => {
  const { question, event, guest } = props;
  const { nextRoute } = React.useContext(RouteContext);
  const { track } = React.useContext(AnalyticsContext);
  const { getAllQuestions } = React.useContext(EventContext);
  const { updateGuestAnswer } = React.useContext(InvitationContext);

  const questionIds = React.useMemo(() => getAllQuestions()?.keys(), [getAllQuestions]);
  const nthQuestion = React.useMemo(() => questionIds && Array.from(questionIds).indexOf(question.id) + 1, [
    question.id,
    questionIds,
  ]);

  React.useEffect(() => {
    if (guest) {
      track({
        // the nth Question of total questions
        rsvpPage: `Custom Question ${nthQuestion}`,
        questionTitle: question.text,
        answerType: question.answerType,
        questionType: question.type,
      });
    }
  }, [guest, nthQuestion, question.answerType, question.id, question.text, question.type, track]);

  const questionText = question.text;
  const guestName = guest && guest.firstName ? `${guest.firstName} ${guest.lastName || ""}` : "";

  const findPreviousAnswer = React.useCallback(() => {
    const guestInvitation = guest?.invitations?.find(invite => invite.eventId === event.id);
    const previousAnswer = guestInvitation?.answers?.find(answer => answer.questionId === question.id);
    return previousAnswer;
  }, [event.id, guest, question.id]);

  const renderQuestionComponent = () => {
    switch (question.answerType) {
      case OPTION_TYPE:
        return (
          <Select
            keyPrefix={`${guest.id}`}
            question={question}
            findPreviousAnswer={() => {
              const { optionId } = findPreviousAnswer() || {};
              return optionId || undefined;
            }}
            updateAnswer={answer => updateGuestAnswer(answer, event.id, guest.id)}
          />
        );
      case TEXT_TYPE:
        return (
          <ShortAnswer
            question={question}
            findPreviousAnswer={() => {
              const { text } = findPreviousAnswer() || {};
              return text || "";
            }}
            updateAnswer={answer => updateGuestAnswer(answer, event.id, guest.id)}
          />
        );
      default:
        // Move on to the next question if unexpected reasons
        return <Redirect to={nextRoute} />;
    }
  };

  return (
    <FullWidth>
      <Title>{questionText}</Title>
      <GuestName>{guestName}:</GuestName>
      {renderQuestionComponent()}
    </FullWidth>
  );
};

export default EventQuestionPage;
