import { Person } from "../Components/Context";

/*
 * Checks if the guest names have names that need editing
 */

export function checkGuestNames(partyGuests: Person[], weddingSlug: string): string {
  // const shouldRedirect: boolean = areGuestNamesInvalid(partyGuests);
  // TODO: (GDS) Temporarily disabling till GDS APIs are ready

  const shouldRedirect = false;

  if (shouldRedirect) {
    return "edit-name";
  }

  return "events/0";
}
