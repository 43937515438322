import merge from "lodash/merge";

export const getThemeWithRsvpOverrides = wedding => {
  if (!wedding) return {};
  const { theme_styles } = wedding;
  const { RSVP } = theme_styles || {};
  const merged = merge(theme_styles, RSVP);
  const { RSVP: postMergeRSVP, ...mergedThemes } = merged;
  return mergedThemes;
};
