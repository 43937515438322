import React from "react";

import { Title } from "../../helpers";

export interface ConfirmationHeadingProps {
  weddingName: string;
  completed: boolean;
}

export function ConfirmationHeading({ weddingName, completed }: ConfirmationHeadingProps) {
  let text = `All Set! Here's what we sent${weddingName ? ` ${weddingName}.` : "."}`;
  if (completed) {
    text = "Welcome back! Here's a quick recap of your RSVP.";
  }
  return <Title data-testid="confirmation-heading">{text}</Title>;
}
