import React from "react";

import styled from "../helpers/styled";

const Wrapper = styled("div")`
  background-color: rgba(245, 166, 35, 0.2);
  padding: 13px 20px;
  margin-bottom: 15px;
  border-left: 5px solid #f5a623;
  text-align: left;
  font-size: 14px;
`;

export interface IWarningToastProps {
  testId?: string;
}

export class WarningToast extends React.Component<IWarningToastProps> {
  render() {
    return <Wrapper data-testid={this.props.testId || ""}>{this.props.children}</Wrapper>;
  }
}
