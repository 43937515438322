import React from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";

import { EventContext } from "./EventContext";
import { GuestContext } from "./GuestContext";
import { InvitationContext } from "./InvitationContext";
import { getUrlSuffix } from "../../Router";

const RSVP_BASE_PATH = "/rsvp";
const SEND_RSVP_PAGE = "/opt-in";
const CONFIRMATION_PAGE = "/confirmation";

interface IRouteContext {
  pushNextRoute: () => void;
  pushRoute: (string, object) => void;
  nextRoute: string;
  eventIndex: number | undefined;
  questionIndex: number | undefined;
  guestIndex: number | undefined;
}

const defaultValue = {
  pushNextRoute: () => {},
  pushRoute: () => {},
  nextRoute: "",
  eventIndex: undefined,
  questionIndex: undefined,
  guestIndex: undefined,
};
export const RouteContext = React.createContext<IRouteContext>(defaultValue);
export const RouteConsumer = RouteContext.Consumer;

const indexToInt = index => (index ? parseInt(index, 10) : undefined);

export const RouteProvider = props => {
  const { children } = props;
  const { events, eventBody } = React.useContext(EventContext);
  const { guestWeddingQuestions } = eventBody || {};
  const { getGuestById: guestsById } = React.useContext(GuestContext);
  const { getAcceptedGuests } = React.useContext(InvitationContext);
  const {
    weddingSlug,
    eventIndex: stringEventIndex,
    questionIndex: stringQuestionIndex,
    guestIndex: stringGuestIndex,
  } = useParams();
  const history = useHistory();
  const eventIndex = React.useMemo(() => indexToInt(stringEventIndex), [stringEventIndex]);
  const questionIndex = React.useMemo(() => indexToInt(stringQuestionIndex), [stringQuestionIndex]);
  const guestIndex = React.useMemo(() => indexToInt(stringGuestIndex), [stringGuestIndex]);

  const { pathname, search } = useLocation();
  const rsvpPath = React.useMemo(() => pathname.slice(pathname.indexOf(RSVP_BASE_PATH)), [pathname]);

  const getNextGeneralQuestionUrl = (): string => {
    const GENERAL_QUESTIONS_ROUTE = questionIdx => `/questions/${questionIdx}`;

    // no gneral questions go to send page
    if (!guestWeddingQuestions || guestWeddingQuestions.length < 1) return SEND_RSVP_PAGE;

    // go to first general question if coming from a path not /rsvp/questions
    const notFromQuestion = !rsvpPath.includes(`${RSVP_BASE_PATH}/questions`);
    if (notFromQuestion || (!questionIndex && questionIndex !== 0)) return GENERAL_QUESTIONS_ROUTE(0);

    // last general question go to send page
    if (questionIndex >= guestWeddingQuestions.length - 1) return SEND_RSVP_PAGE;

    // go to next general question
    return GENERAL_QUESTIONS_ROUTE(questionIndex + 1);
  };

  const getNextEventUrl = (): string => {
    const EVENTS_ROUTE = eventIdx => `/events/${eventIdx}`;
    const QUESTIONS_ROUTE = (questIdx, guestIdx) => `/questions/${questIdx}/${guestIdx}`;
    // route for either after guest lookup or no events
    // /events/0
    if (!eventIndex && eventIndex !== 0) return events.length ? EVENTS_ROUTE(0) : getNextGeneralQuestionUrl();

    const currentEvent = events[eventIndex];
    const nextEventIndex = eventIndex + 1;
    // /events/nextIndex
    const nextEventRoute = EVENTS_ROUTE(nextEventIndex);
    const isLastEvent = eventIndex >= events.length - 1;

    // safety check that currentEvent is not undefined
    if (!currentEvent) return !isLastEvent ? nextEventRoute : getNextGeneralQuestionUrl();

    const eventQuestions = currentEvent?.questions || [];
    const hasQuestions = eventQuestions.length > 0;

    const acceptedGuests = getAcceptedGuests(currentEvent.id, guestsById);
    const hasGuests = acceptedGuests?.length > 0;

    // route for no event questions present or all guests declined
    if (!hasQuestions || !hasGuests) return !isLastEvent ? nextEventRoute : getNextGeneralQuestionUrl();

    // route from event response
    if ((!questionIndex && questionIndex !== 0) || (!guestIndex && guestIndex !== 0))
      // /events/eventIndex/questions/0/0
      return `${EVENTS_ROUTE(eventIndex)}${QUESTIONS_ROUTE(0, 0)}`;

    const isLastAcceptedGuest = guestIndex >= acceptedGuests.length - 1;
    const isLastQuestion = questionIndex >= eventQuestions.length - 1;

    // route to next for on last question and last accepted guest
    if (isLastQuestion && isLastAcceptedGuest) return !isLastEvent ? nextEventRoute : getNextGeneralQuestionUrl();

    const nextQuestionIndex = questionIndex + 1;
    // route to next question for on last accepted guest of current question
    // /events/eventIndex/questions/nextIndex/0
    if (isLastAcceptedGuest) return `${EVENTS_ROUTE(eventIndex)}${QUESTIONS_ROUTE(nextQuestionIndex, 0)}`;

    const nextAcceptedGuestIndex = guestIndex + 1;
    // route to move on to the next accepted guest of current question
    // /events/eventIndex/questions/nextIndex/nextIndex
    return `${EVENTS_ROUTE(eventIndex)}${QUESTIONS_ROUTE(questionIndex, nextAcceptedGuestIndex)}`;
  };

  // call appropriate function based on current path
  const determineNextPath = (): string => {
    // if on events path determine next path
    if (rsvpPath.includes(`${RSVP_BASE_PATH}/events`)) return getNextEventUrl();
    // if on general questions path determine next path
    else if (rsvpPath.includes(`${RSVP_BASE_PATH}/questions`)) return getNextGeneralQuestionUrl();
    // from opt-in send to confirmation
    else if (rsvpPath === `${RSVP_BASE_PATH}${SEND_RSVP_PAGE}`) return CONFIRMATION_PAGE;
    return SEND_RSVP_PAGE;
  };

  const nextPath = determineNextPath();
  const suffix = getUrlSuffix({ weddingSlug });
  const nextRoute = `${suffix}${nextPath}${search}`;

  const pushRoute = (route, state = {}) => {
    const { push } = history;
    push(route, state);
  };

  return (
    <RouteContext.Provider
      value={{
        pushNextRoute: () => pushRoute(nextRoute),
        pushRoute: (route, state) => pushRoute(`${suffix}${route}${search}`, state),
        nextRoute,
        eventIndex,
        questionIndex,
        guestIndex,
      }}
    >
      {children}
    </RouteContext.Provider>
  );
};
