import React from "react";

import styled from "../../helpers/styled";
import { WeddingName } from "../../Components";
import { Body } from "../../helpers";

const Wrapper = styled("div")`
  margin-bottom: 40px;
`;

function Title() {
  return (
    <Wrapper>
      <WeddingName />
      <Body>
        If you're responding for you and a guest (or your family), you'll be able to RSVP for your entire group.
      </Body>
    </Wrapper>
  );
}

export default Title;
