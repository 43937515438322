import React, { useContext, useState } from "react";

import styled from "../../helpers/styled";
import { AnalyticsContext, Location } from "../../Components/Context";
import { Body, IconWrapper, Link, EventIcon } from "../../helpers";

export interface IEventLocationProps {
  location?: Location | null;
}

export interface IEventLocationState {
  showFullAddress: boolean;
}

const NoMarginBody = styled(Body)`
  margin-bottom: 0;
`;

export function EventLocation({ location }: IEventLocationProps) {
  const [showFullAddress, setShowFullAddress] = useState(false);
  const { track } = useContext(AnalyticsContext);

  if (!location) {
    return null;
  }

  const { name = "", fullAddress = "" } = location;

  const icon = <EventIcon name="map" size="sm" />;

  if (!name && !fullAddress) {
    return null;
  }

  if (name && fullAddress) {
    return (
      <Body data-testid="event-location">
        <IconWrapper>{icon}</IconWrapper>
        <div>
          {name}
          {!showFullAddress && (
            <Link
              onClick={() => {
                setShowFullAddress(true);
                track({
                  selection: "view event location",
                  rsvpPage: "event response",
                });
              }}
            >
              View Full Address
            </Link>
          )}

          {showFullAddress && <NoMarginBody data-testid="event-location-full-address">{fullAddress}</NoMarginBody>}
        </div>
      </Body>
    );
  }

  return (
    <Body data-testid="event-location">
      <IconWrapper>{icon}</IconWrapper>
      {name && name} {fullAddress && fullAddress}
    </Body>
  );
}
