import React from "react";

import { IconWrapper, Body, EventIcon } from "../../helpers";

export interface EventAttireProps {
  attire?: string;
}

export function EventAttire({ attire }: EventAttireProps) {
  if (attire) {
    return (
      <Body data-testid="event-attire">
        <IconWrapper>
          <EventIcon name="attire" size="sm" />
        </IconWrapper>
        {attire}
      </Body>
    );
  }
  return null;
}
