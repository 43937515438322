import { InvitationState } from "../Components/Context";

export function hasMixedStatus(invitations: InvitationState): boolean {
  const responsesArray = Object.keys(invitations).reduce((initial: (boolean | null)[], key) => {
    if (Object.keys(invitations[key]).length) {
      const statues = Object.keys(invitations[key]).map(guestKey => invitations[key][guestKey].rsvp);

      return [...initial, ...statues];
    }

    return initial;
  }, []);

  return [...new Set(responsesArray)].length === 1 ? false : true;
}
