import { toPairs } from "lodash";
import React, { useContext } from "react";
import get from "lodash-es/get";
import Icon from "@xo-union/tk-component-icons";

import { EventContext, InvitationContext, WeddingContext, RouteContext, GuestContext } from "../../Components/Context";
import { BoldText, FlexSpaceBtw, Link, Hr as BaseHr, onKeyEnterSpace } from "../../helpers";
import { AddToCalendar } from "./AddToCalendar";
import { RsvpNavOptions } from "./Confirmation";
import { GuestResponseItem, LocationType } from "./GuestResponseItem";
import styled from "../../helpers/styled";

const EventsContainer = styled("div")`
  margin: 25px 0;
`;

const EventName = styled(BoldText)`
  font-size: 20px;
`;

const Hr = styled(BaseHr)`
  margin: 10px 0;
`;

const ListGuestLink = styled(Link)`
  display: inline-block;
  margin-top: 1.25rem;
  font-size: 1rem;
  border-bottom: none;
  margin-left: 0;
  text-transform: capitalize;

  &:hover,
  &:focus {
    font-size: 1rem;
    border-bottom: none;
  }

  & svg {
    margin-left: 0.5rem;
    vertical-align: middle;
  }
`;

export function ConfirmationEvents({ rsvpNavOptions }: { rsvpNavOptions: RsvpNavOptions }) {
  const { invitations } = useContext(InvitationContext);
  const event = useContext(EventContext);
  const { wedding } = useContext(WeddingContext);
  const { guestAttendance } = useContext(GuestContext);
  const { pushRoute } = useContext(RouteContext);

  return (
    <EventsContainer>
      {Object.keys(invitations).map((eventId, eventIdx, selfArray) => {
        const eventDetails = event.getEventDetails(eventId);
        if (eventDetails) {
          const eventGuests = invitations[eventId];
          const length = Object.keys(eventGuests).length;
          if (length) {
            const showGuests = !!guestAttendance && Object.values(eventGuests).some(({ rsvp }) => rsvp);
            return (
              <div key={eventId}>
                <EventsContainer>
                  <FlexSpaceBtw>
                    <EventName>{eventDetails.name}</EventName>
                    {get(wedding, ["hide_date"]) ? null : (
                      <AddToCalendar eventId={eventId} rsvpNavOptions={rsvpNavOptions} />
                    )}
                  </FlexSpaceBtw>
                  {toPairs(eventGuests)
                    .reverse()
                    .map(([key, value], index) => {
                      let location: LocationType = "middle";
                      if (index === 0) {
                        location = "first";
                      } else if (index === length - 1) {
                        location = "last";
                      }

                      return (
                        <GuestResponseItem
                          event={eventDetails}
                          key={key}
                          guestId={key}
                          rsvp={value.rsvp}
                          location={location}
                        />
                      );
                    })}
                  {showGuests && (
                    <ListGuestLink
                      tabIndex={0}
                      onClick={() => pushRoute("/confirmation/preview", { eventId })}
                      onKeyDown={e => onKeyEnterSpace(e, () => pushRoute("/confirmation/preview", { eventId }))}
                    >
                      See who's attending
                      <Icon name="caret_right" />
                    </ListGuestLink>
                  )}
                </EventsContainer>
                {eventIdx < selfArray.length - 1 && <Hr />}
              </div>
            );
          }

          return null;
        }

        return null;
      })}
    </EventsContainer>
  );
}
