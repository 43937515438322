import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { WeddingProvider, InvitationProvider } from "./Components/Context";
import StepContainer from "./Components/StepContainer";
import { IHistoryPropsNoPush } from "./helpers";
import { InvitationProviderWrapper } from "./InvitationProviderWrapper";
import { ProtectedRoute } from "./ProtectedRoute";
import {
  AddGuests,
  Confirmation,
  EditName,
  GuestResponse,
  GuestSearch,
  OptIn,
  PartialMatch,
  EventQuestionPage,
  GeneralQuestionPage,
  GuestAttendanceList,
} from "./views";

export function getUrlSuffix(params: any): string {
  if (
    window.location.pathname.includes(`/us/${params.weddingSlug}`) ||
    window.location.hostname === process.env.REACT_APP_WWS_HOSTNAME ||
    window.location.hostname === process.env.REACT_APP_RSVP_HOSTNAME
  ) {
    return `/us/${params.weddingSlug}/rsvp`;
  } else {
    return `/rsvp`;
  }
}

export function pushRoute(
  route: string,
  params: any,
  query: string,
  push: (path: string, state?: any) => void,
  state?: any
) {
  push(`${getUrlSuffix(params)}/${route}${query}`, state);
}

const routes: any = [
  {
    name: "",
    Component: GuestSearch,
    notProtected: true,
  },
  {
    name: "partial_match",
    Component: PartialMatch,
  },
  {
    name: "add-guests",
    Component: AddGuests,
  },
  {
    name: "edit-name",
    Component: EditName,
  },
  {
    name: "events/:eventIndex",
    Component: GuestResponse,
  },
  {
    name: "opt-in",
    Component: OptIn,
  },
  {
    name: "confirmation",
    Component: Confirmation,
  },
  {
    name: "confirmation/preview",
    Component: GuestAttendanceList,
  },
  {
    name: "events/:eventIndex/questions/:questionIndex/:guestIndex",
    Component: EventQuestionPage,
  },
  {
    name: "questions/:questionIndex",
    Component: GeneralQuestionPage,
  },
];

const parentRoutes = ["/us/:weddingSlug/rsvp", "/rsvp"];

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/health" />

      {parentRoutes.map(route => (
        <Route
          key={route}
          path={route}
          render={props => (
            <InvitationProvider {...(props as IHistoryPropsNoPush)}>
              <InvitationProviderWrapper
                {...(props as IHistoryPropsNoPush)}
                pushRoute={route => pushRoute(route, props.match.params, props.location.search, props.history.push)}
              >
                <Switch>
                  {routes.map(({ name, Component, notProtected }) =>
                    notProtected ? (
                      <Route
                        key={name}
                        exact
                        path={`${route}/${name}`}
                        render={p => (
                          <StepContainer>
                            <Component
                              {...p}
                              pushRoute={(route, state) =>
                                pushRoute(route, p.match.params, props.location.search, p.history.push, state)
                              }
                            />
                          </StepContainer>
                        )}
                      />
                    ) : (
                      <ProtectedRoute key={name} exact path={`${route}/${name}`} component={Component} />
                    )
                  )}
                </Switch>
              </InvitationProviderWrapper>
            </InvitationProvider>
          )}
        />
      ))}
      <Route
        path="/"
        render={props => (
          <WeddingProvider {...(props as IHistoryPropsNoPush)}>
            <h2>Route Not Found</h2>
          </WeddingProvider>
        )}
      />
    </Switch>
  </BrowserRouter>
);

export default Router;
