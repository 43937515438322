import { PreferenceResponse } from "../views/OptIn/GuestMessagingService";

export function determineOptInDetails(
  existingPreferences: PreferenceResponse,
  weddingName: string,
  selectedPreference: string
) {
  let optInDetails = "";
  let optedOutPref = "";
  let newPrefToSelect = "";

  if (existingPreferences.smsGlobalAllowed === false && selectedPreference === "phone") {
    // If user is globally opted out (via STOP), select email by default
    newPrefToSelect = "email";
    optInDetails =
      "It looks like you’ve opted out of receiving SMS messages. To get your RSVP confirmation via SMS, text “START” to 87292.";
    optedOutPref = "phone";
  } else if (existingPreferences.smsOptIn === false && existingPreferences.emailOptIn === false) {
    // Do nothing, defer to the already selected optInPref based on contact info
    optInDetails = `You have previously opted out of receiving messages from ${weddingName}. By selecting this confirmation you will be opted back in.`;
    optedOutPref = "both";
  } else if (existingPreferences.smsOptIn === false) {
    // If user is opted out of SMS, select email by default
    newPrefToSelect = "email";
    optInDetails = `Sending this confirmation allows ${weddingName} to message you again in the future via SMS.`;
    optedOutPref = "phone";
  } else if (existingPreferences.emailOptIn === false) {
    // If user is opted out of email, select SMS by default
    newPrefToSelect = "phone";
    optInDetails = `Sending this confirmation allows ${weddingName} to message you again in the future via Email.`;
    optedOutPref = "email";
  }
  return { optInDetails, optedOutPref, newPrefToSelect };
}
