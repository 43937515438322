import { Option, Select } from "@xo-union/tk-component-fields";
import React from "react";

import { detectPlatform } from "../helpers/detectPlatform";
import styled from "../helpers/styled";
import arrow from "./arrow-down.svg";

export interface IOptionsProps {
  id: string | number;
  name: string;
}

export interface IDropdownProps {
  placeholder: string;
  options: string[];
  value: string | string[] | number | undefined;
  onChange: (value) => void;
}

const NativeSelect = styled("select")`
  background-color: ${({ theme }) => `${theme.white} !important`};
  height: 50px;
  border-radius: 2px;
  width: 100%;
  border: 1px solid ${({ theme, value }) => (value ? theme.black : theme["gray-7"])};
  appearance: "none";
  -webkit-appearance: none;
  --moz-appearance: none;
  color: ${({ theme, value }) => (value ? theme.black : theme["gray-5"])};
  font-size: 18px;
  padding: 0 18px;
  background: url(${arrow}) right center no-repeat;

  &:focus {
    border: 1px solid ${({ theme }) => theme.black};
    color: ${({ theme }) => theme.black};
    outline: none;
  }
`;

export function Dropdown({ placeholder, options, value, onChange }: IDropdownProps) {
  if (detectPlatform() === "desktop") {
    return (
      <Select
        name={placeholder}
        onChange={e => onChange(e.option.value)}
        value={value}
        data-testid="dropdown-component"
      >
        {options.map(name => (
          <Option key={name} value={name} label={name} />
        ))}
      </Select>
    );
  }

  return (
    <NativeSelect
      placeholder={placeholder}
      value={value}
      onChange={e => onChange(e.target.value)}
      data-testid="dropdown-component"
    >
      <option hidden disabled value="">
        {placeholder}
      </option>
      {options.map(name => (
        <option key={name} value={name}>
          {name}
        </option>
      ))}
    </NativeSelect>
  );
}
