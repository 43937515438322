import React from "react";
import { ProgressBar as UnionProgressBar } from "@xo-union/tk-component-progress-bar";
import get from "lodash/get";
import { useTheme } from "emotion-theming";

import { progressFromURLAndEvents, determineStepsAndFactor } from "../helpers";
import { EventContext, InvitationContext, GuestContext } from "./Context";

const ThemeProgressBar = ({ progress }) => {
  const theme = useTheme();
  return <UnionProgressBar color={get(theme, "colors.primary")} progress={progress} />;
};

const ProgressBar = () => {
  const { events, eventBody } = React.useContext(EventContext);
  const { guestWeddingQuestions } = eventBody || {};
  const { getGuestById: guestsById } = React.useContext(GuestContext);
  const { getAcceptedGuests } = React.useContext(InvitationContext);
  const { pathname } = window.location;
  const pathParts = React.useMemo(() => pathname.split("/"), [pathname]);
  const isEventQuestion = React.useMemo(() => pathParts.includes("events") && pathParts.includes("questions"), [
    pathParts,
  ]);
  const { stepMap, stepFactor } = React.useMemo(() => determineStepsAndFactor(events, guestWeddingQuestions), [
    events,
    guestWeddingQuestions,
  ]);

  const determineProgress = React.useCallback((): number => {
    const lastIndex = pathParts.length - 1;
    const progress = progressFromURLAndEvents(pathname, stepMap, stepFactor);
    // divide the step for event questions by guests
    if (isEventQuestion) {
      const eventIndex = pathParts[lastIndex - 3];
      const event = events[eventIndex];
      // check to not break if refresh mid-progression
      if (!event) return progress;

      const guests = getAcceptedGuests(event.id, guestsById);
      const guestIndex = Number(pathParts[lastIndex]);
      const guestProgress = guestIndex / (guests.length + 1);

      return progress + guestProgress * stepFactor;
    }
    return progress;
  }, [events, getAcceptedGuests, guestsById, isEventQuestion, pathParts, pathname, stepFactor, stepMap]);

  return <ThemeProgressBar progress={determineProgress() || 0} />;
};

export default ProgressBar;
