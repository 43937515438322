import { includes } from "lodash";
import React, { useContext } from "react";

import { AnalyticsContext } from "../../Components/Context";
import ThemedButton from "../../ThemedButton";
import { FullWidth } from "../../helpers";
import styled from "../../helpers/styled";
import { RsvpNavOptions } from "./Confirmation";

interface ConfirmationButtonsProps {
  rootWebUrl: string;
  rsvpNavOptions: RsvpNavOptions;
}

const Wrapper = styled(FullWidth)`
  text-align: center;
  margin-top: 15px;
`;

export function ConfirmationButtons({ rootWebUrl, rsvpNavOptions }: ConfirmationButtonsProps) {
  const { track } = useContext(AnalyticsContext);

  return (
    <>
      {includes(rsvpNavOptions, "gift") && (
        <ThemedButton
          text="Browse Registry"
          onClick={() => {
            track({
              selection: "registry",
              rsvpPage: "rsvp confirmation",
              rsvpNavOptions,
            });
            window.location.assign(`${rootWebUrl}/registry`);
          }}
        />
      )}
      <Wrapper>
        <ThemedButton
          onClick={() => {
            track({
              selection: "wws home",
              rsvpPage: "rsvp confirmation",
              rsvpNavOptions,
            });
            window.location.assign(rootWebUrl);
          }}
          text="Back to Homepage"
        />
      </Wrapper>
    </>
  );
}
