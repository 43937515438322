import Alert from "@xo-union/tk-component-alerts";
import React, { useCallback, useContext, useEffect, useRef } from "react";

import { GuestContext, PreviewContext } from "../../Components/Context";
import { FullWidth, systemError } from "../../helpers";
import { GuestSearchFormContainer } from "./GuestSearchFormContainer";
import PreviewBanner from "../../Components/PreviewBanner";
import Title from "./Title";

export function GuestSearch(props) {
  const { setGidCookie, resetState, getGuestsForWedding } = useContext(GuestContext);
  const { preview } = useContext(PreviewContext);
  const alertRef = useRef<any>(null);
  const setCookie = useCallback(async () => {
    try {
      await setGidCookie();
    } catch (error) {
      alertRef.current.show({
        type: "error",
        content: systemError,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!preview) {
      setCookie();
    }
    resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FullWidth>
      <Alert ref={alertRef} position="bottom" />
      <Title />
      <GuestSearchFormContainer getGuestsForWedding={getGuestsForWedding} {...props} />
      {preview ? <PreviewBanner /> : null}
    </FullWidth>
  );
}
