import { Event, Question } from "../Components/Context";

export const determineStepsAndFactor = (events: Event[], generalQuestions: Question[] = []) => {
  // Create an array of all the steps
  const steps = ["rsvp", "partial_match", "edit-name", "add-guests"];

  // Add Events/Question to steps
  events.forEach((e, i) => {
    steps.push(`events/${i}`);
    if (e.questions?.length) {
      e.questions.forEach((_, idx) => {
        steps.push(`${i} questions ${idx}`);
      });
    }
  });

  if (generalQuestions && generalQuestions.length) {
    generalQuestions.forEach((_, i) => {
      steps.push(`questions/${i}`);
    });
  }

  // Push the steps that come after events
  steps.push("opt-in", "confirmation");

  // Create a map of all the steps at their index
  const stepMap = {};
  steps.forEach((s, i) => (stepMap[s] = i));

  const stepFactor = 1 / (steps.length - 1);
  return { stepMap, stepFactor };
};

export function progressFromURLAndEvents(url: string, stepMap, stepFactor): number {
  if (url === "" || url === undefined) return 0;
  // Get current route from location
  const pathParts = url.split("/");
  const route = pathParts[pathParts.length - 1];
  const routeTwo = pathParts[pathParts.length - 2];
  const routeThree = pathParts[pathParts.length - 3];
  let step;
  if (routeThree === "questions") {
    // Get the event index from the path parts
    const eventIndex = pathParts[pathParts.length - 4];
    const questionIndex = routeTwo;
    step = stepMap[`${eventIndex} questions ${questionIndex}`];
  } else if (route === "rsvp") {
    // Make the progress bar fill just a little bit on the very first step
    step = 0.25;
  } else if (stepMap[route]) {
    // This is a non event or meals route
    // It will exist in stepmap
    step = stepMap[route];
  } else if (routeTwo === "events" || routeTwo === "questions") {
    // This is an event route "/rsvp/[events || questions]/:index"
    step = stepMap[`${routeTwo}/${route}`];
  } else if (pathParts.includes("confirmation")) {
    // This is so progress does not move at the end
    step = stepMap.confirmation;
  } else {
    // or a rouate that has since been added to the router but was missed here :shrug:
    step = stepMap[`${route}`];
  }

  return step * stepFactor;
}
