import { toPairs } from "lodash";

import { InvitationState } from "../Components/Context";

export function allAnswerSelectedForEventQuestion(
  invitations: InvitationState,
  eventId: string,
  questionId: string
): boolean {
  return toPairs(invitations[eventId]).reduce<boolean>((initial, [, val]) => {
    if (initial === false) {
      return false;
    }
    if (val.rsvp === true && !val?.answers?.length) {
      return false;
    }

    return true;
  }, true);
}
