import { Global } from "@emotion/core";
import get from "lodash-es/get";
import React, { useContext } from "react";
import { useTheme } from "emotion-theming";
import { useLocation } from "react-router-dom";

import { ThemeWrapper } from "../helpers/CSSHelpers";
import styled, { ITheme } from "../helpers/styled";
import { WeddingContext, PreviewContext } from "./Context";

const Wrapper = styled("div")`
  height: 80%;
  min-height: 350px;
  width: 480px;
  padding: 0 20px;
  margin-bottom: 80px;
  text-align: left;
  z-index: 2;
  padding-top: 5px;
  margin: auto;
  @media (max-width: 480px) {
    width: 100%;
    min-height: 400px;
  }
`;

const TopThemeImageContainer = styled("div")`
  height: 20%;
  display: block;
  width: 100%;
  max-width: 100vw;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  ${({ theme }) => ({ ...get(theme, ["components", "TopImage"]) })}
`;

const StyledPicture = styled("picture")`
  width: 100%;
`;

const StyledImage = styled("img")`
  width: 100%;
`;

const TopThemePicture = () => {
  const theme: ITheme = useTheme();
  const { responsiveImages, images } = theme;

  const { top: topResponsive } = responsiveImages || {};
  const { top: topImageUrl } = images || {};

  return (
    <StyledPicture>
      <source media="(min-width: 1280px)" srcSet={get(topResponsive, "xlUrl", topImageUrl)} />
      <source media="(min-width: 1024px)" srcSet={get(topResponsive, "lgUrl", topImageUrl)} />
      <source media="(min-width: 768px)" srcSet={get(topResponsive, "mdUrl", topImageUrl)} />
      <source srcSet={get(topResponsive, "smUrl", topImageUrl)} />
      <StyledImage src={topImageUrl} alt="" />
    </StyledPicture>
  );
};

const StepContainer = ({ children }) => {
  const { wedding } = useContext(WeddingContext);
  const { preview } = useContext(PreviewContext);
  const { pathname } = useLocation();
  const backgroundColor = get(wedding, ["theme_styles", "colors", "background"]);
  const hasCovidBanner = get(wedding, ["announcement", "show"]);
  const routeName = pathname.split("/").pop();
  const hasPreviewBanner = preview && (routeName === "rsvp" || routeName === "confirmation" || routeName === "");

  return (
    <>
      <Global
        styles={{
          body: {
            backgroundColor,
          },
        }}
      />
      <ThemeWrapper hasCovidBanner={hasCovidBanner} hasPreviewBanner={hasPreviewBanner} data-testid="theme-wrapper">
        <TopThemeImageContainer>
          <TopThemePicture />
        </TopThemeImageContainer>
        <Wrapper>{children}</Wrapper>
      </ThemeWrapper>
    </>
  );
};

export default StepContainer;
