import Axios, { AxiosResponse } from "axios";

import { IEvent } from "./EventContext";
import { IAttendanceMap } from "./IGuestContext";

export interface IEventService {
  searchForEvent: (weddingUuid: string) => Promise<AxiosResponse<IEvent>>;
  getAttendanceList: (weddingUuid: string, householdId: string) => Promise<AxiosResponse<IAttendanceMap>>;
}

const { REACT_APP_GDS_URL } = process.env;

const eventService: IEventService = {
  searchForEvent(weddingUuid) {
    return Axios.get(`${REACT_APP_GDS_URL}/v1/weddings/${weddingUuid}/events`);
  },
  getAttendanceList(weddingUuid, householdId) {
    return Axios.get(`${REACT_APP_GDS_URL}/v1/weddings/${weddingUuid}/attendance/${householdId}`);
  },
};

export default eventService;
