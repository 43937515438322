import React, { useContext } from "react";

import {
  AnalyticsProvider,
  EventProvider,
  GuestProvider,
  InvitationContext,
  WeddingProvider,
  PreviewProvider,
} from "./Components/Context";
import { IHistoryProps } from "./helpers";
import { Layout } from "./Layout";

export function InvitationProviderWrapper(props: IHistoryProps) {
  const value = useContext(InvitationContext);
  return (
    <PreviewProvider {...props}>
      <WeddingProvider {...props}>
        <EventProvider {...value} {...props}>
          <GuestProvider {...value} {...props}>
            <AnalyticsProvider>
              <Layout {...props} noTrack={false}>
                {props.children}
              </Layout>
            </AnalyticsProvider>
          </GuestProvider>
        </EventProvider>
      </WeddingProvider>
    </PreviewProvider>
  );
}
