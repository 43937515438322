import analytics from "@xo-union/tk-analytics/lib/wrapper";
import Cookie from "js-cookie";
import React, { useCallback, useContext } from "react";

import { detectPlatform } from "../../helpers/detectPlatform";
import { getMealCount } from "../../helpers/getMealCount";
import { collectQuestionTypes, getMaxPageCount } from "../../helpers/analytics";
import { EventContext } from "./EventContext";
import { WeddingContext } from "./WeddingContext";

interface AnalyticsState {
  track: (attributes: any, message?: string) => void;
}

const defaultValue = {
  track: () => {},
};

export const AnalyticsContext = React.createContext<AnalyticsState>(defaultValue);
export const AnalyticsConsumer = AnalyticsContext.Consumer;

export function AnalyticsProvider(props) {
  const { wedding, weddingSlug } = useContext(WeddingContext);
  const { eventBody, events, getAllQuestions } = useContext(EventContext);

  const getGid = useCallback(() => {
    return Cookie.get("gid");
  }, []);

  const track = useCallback((attributes: any, message = "RSVP Interaction") => {
    if (wedding) {
      const hasWWSAccommodations = wedding.accommodations.length ? true : false;
      const hasWWSCoverPhoto = wedding.cover_photo ? true : false;
      const hasWWSFirstNames = wedding.fiance_first_name && wedding.first_name ? true : false;
      const hasWWSLastNames = wedding.fiance_last_name && wedding.last_name ? true : false;
      const hasWWSWeddingLocation = wedding.wedding_location ? true : false;
      const hasWWSWeddingDate = wedding.wedding_date ? true : false;
      const customDomain = wedding.custom_domain ? true : false;
      const rsvpMealCount = getMealCount(events);
      const weddingDate = wedding.wedding_date ? new Date(wedding.wedding_date) : null;
      const hasThemes = Object.keys(wedding.theme_styles || {}).length > 0;
      const wwsTemplateVersion = hasThemes ? "v2" : "v1";
      const questionById = getAllQuestions();
      const questionCount = questionById ? questionById.size : 0;
      const questionTypes = questionById ? collectQuestionTypes(questionById) : [];
      const rsvpMaxPages = getMaxPageCount(events, eventBody?.guestWeddingQuestions);
      const publicRSVPList = eventBody?.allowGuestPreview || false;

      const properties = {
        eventCount: eventBody && eventBody.eventCount,
        rsvpOnCount: eventBody && eventBody.rsvpableCount,
        security: eventBody && eventBody.isPrivateRsvp ? "private" : "public",
        platform: detectPlatform(),
        weddingDate,
        weddingUUID: wedding.wedding_uuid,
        product: "wedding websites", // TODO: TBD if this should be RSVP
        weddingSlug: weddingSlug,
        hasWWSAccommodations,
        hasWWSCoverPhoto,
        hasWWSFirstNames,
        hasWWSLastNames,
        hasWWSWeddingLocation,
        hasWWSWeddingDate,
        customDomain,
        daysUntilWedding: wedding.days_until_wedding,
        wwsVanity: wedding.vanity_url,
        wwsTemplateVersion,
        rsvpMealCount,
        guestUUID: getGid(),
        rsvpMaxPages,
        wwsThemeID: wedding.theme_id,
        wwsThemeName: wedding.theme_name,
        questionCount,
        questionTypes,
        publicRSVPList,
      };

      analytics.track(message, {
        ...properties,
        ...attributes,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AnalyticsContext.Provider value={{ track }}>{props.children}</AnalyticsContext.Provider>;
}
