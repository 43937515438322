import { format } from "date-fns";
import React from "react";

import { IconWrapper, Body, EventIcon } from "../../helpers";

export interface IEventDate {
  date?: string;
  time?: string;
}

export function EventDate({ date = "", time = "" }: IEventDate) {
  let formattedDate = "";
  let formattedTime = "";
  const formatString = "hh:mm A";
  const dateFormat = "YYYY/MM/DD";
  if (!date && !time) {
    return null;
  }

  if (date) {
    const safeDate = `${date} 12:00:00 UTC`; // Use 12 UTC when converting dates without times, so that daylight savings doesn't cause the date to change.
    formattedDate = format(safeDate, "dddd, MMMM DD, YYYY");

    if (time) {
      const formatted = format(safeDate, dateFormat);
      formattedTime = format(new Date(`${formatted} ${time}`), formatString);
    }
  } else {
    if (time) {
      const tempDate = format(new Date(), dateFormat);
      formattedTime = format(new Date(`${tempDate} ${time}`), formatString);
    }
  }

  return (
    <Body data-testid="event-date">
      <IconWrapper>
        <EventIcon name="date" size="sm" />
      </IconWrapper>
      {formattedDate} {formattedTime && ` at ${formattedTime}`}
    </Body>
  );
}
