import get from "lodash-es/get";
import React, { createContext, useReducer } from "react";

import { allGuestsResponded, allAnswerSelectedForEventQuestion, IHistoryPropsNoPush } from "../../helpers";
import { Event } from "./EventContext";
import { IGetGuestById, Invitation, Person } from "./IGuestContext";

export interface InvitationByGuest {
  [guestId: string]: Invitation;
}

export interface InvitationState {
  [key: string]: InvitationByGuest;
}

export interface IEmptyEventsObj {
  [eventId: string]: {};
}

export interface AnswerOption {
  questionId: string;
  optionId: string;
}

export interface AnswerText {
  questionId: string;
  text: string;
}

export type IUpdateGuestRsvp = (rsvpStatus: boolean | null, guestId: string, eventId: string) => void;

export type ISelectGuestMeal = (meal: string, eventId: string, guestId: string) => void;

export type ISelectAnswerOption = (answer: AnswerOption, eventId: string, guestId: string) => void;

export interface IInvitationContext {
  invitations: InvitationState;
  addEvents: (events: Event[]) => void;
  addGuestsToEvents: (partyGuests: Person[]) => void;
  updateGuestRsvp: IUpdateGuestRsvp;
  isGuestResponseValid: (eventId: string) => boolean;
  updateAllRsvpForGuest: (guestId: string, rsvpStatus: boolean | "") => void;
  getAcceptedGuests: (eventId: string, getGuestById: IGetGuestById) => Person[];
  updateGuestAnswer: (answer: AnswerText | AnswerOption, eventId: string, guestId: string) => void;
  allAnswerSelectedForEventQuestion: (eventId: string, questionId: string) => boolean;
}

export type InvitationAction =
  | { type: "ADD_EVENTS"; payload: IEmptyEventsObj }
  | { type: "ADD_GUESTS_EVENTS"; payload: InvitationState }
  | { type: "UPDATE_GUEST_RSVP"; payload: InvitationState }
  | { type: "UPDATE_GUEST_RSVP_ALL_EVENTS"; payload: InvitationState }
  | { type: "UPDATE_GUEST_ANSWER"; payload: InvitationState };

const defaultValue = {
  invitations: {},
  addEvents: () => {},
  addGuestsToEvents: () => {},
  updateGuestRsvp: () => {},
  isGuestResponseValid: () => false,
  updateAllRsvpForGuest: () => {},
  getAcceptedGuests: () => [],
  selectGuestMeal: () => {},
  updateGuestAnswer: () => {},
  allAnswerSelectedForEventQuestion: () => false,
};

export const InvitationContext = createContext<IInvitationContext>(defaultValue);
export const InvitationConsumer = InvitationContext.Consumer;

const ADD_EVENTS = "ADD_EVENTS";
const ADD_GUESTS_EVENTS = "ADD_GUESTS_EVENTS";
const UPDATE_GUEST_RSVP = "UPDATE_GUEST_RSVP";
const UPDATE_GUEST_RSVP_ALL_EVENTS = "UPDATE_GUEST_RSVP_ALL_EVENTS";
const UPDATE_GUEST_ANSWER = "UPDATE_GUEST_ANSWER";

function reducer(state: InvitationState, action: InvitationAction): InvitationState {
  switch (action.type) {
    case ADD_EVENTS:
    case ADD_GUESTS_EVENTS:
    case UPDATE_GUEST_RSVP:
    case UPDATE_GUEST_RSVP_ALL_EVENTS:
    case UPDATE_GUEST_ANSWER:
      return { ...state, ...action.payload };

    default:
      return state;
  }
}

export function InvitationProvider(props: IHistoryPropsNoPush) {
  const [invitations, dispatch] = useReducer(reducer, {});

  const addEvents = (events: Event[]) => {
    const eventIds = events.reduce<IEmptyEventsObj>((initial, { id }) => ({ ...initial, [id]: {} }), {});
    dispatch({ type: ADD_EVENTS, payload: eventIds });
  };

  const addGuestsToEvents = (partyGuests: Person[]) => {
    const wGuests = Object.keys(invitations).reduce((initial, key) => {
      const newG: InvitationByGuest | {} = partyGuests.reduce((init, guest) => {
        const invite = guest.invitations && guest.invitations.find(i => i.eventId === key);
        if (invite) {
          return {
            [guest.id]: {
              id: invite.id,
              rsvp: invite.rsvp,
              eventId: invite.eventId,
              answers: invite.answers,
            },
            ...init,
          };
        }

        return init;
      }, {});
      return { ...initial, [key]: newG };
    }, invitations);

    dispatch({ type: ADD_GUESTS_EVENTS, payload: wGuests });
  };

  const updateGuestRsvp = (rsvpStatus: boolean | null, guestId: string, eventId: string) => {
    const event = invitations[eventId];
    const guest = event ? event[guestId] : null;

    if (guest) {
      const updatedResponse = {
        [eventId]: {
          ...event,
        },
      };

      updatedResponse[eventId][guestId] = {
        ...guest,
        rsvp: rsvpStatus,
      };

      dispatch({ type: UPDATE_GUEST_RSVP, payload: updatedResponse });
    }
  };

  const isGuestResponseValid = (eventId: string): boolean => {
    // allGuestsResponded function returns if the guests responded
    // the button should be disabled on opposite of the response
    return !allGuestsResponded(invitations, eventId);
  };

  const updateAllRsvpForGuest = (guestId: string, rsvpStatus: boolean | "") => {
    const updatedResponses = Object.keys(invitations).reduce<any>((initial, eventKey) => {
      const event: InvitationByGuest = invitations[eventKey];

      const isInvitedToEvent = get(event, [guestId], undefined);
      if (isInvitedToEvent) {
        const updated = {
          ...event,
          [guestId]: {
            ...event[guestId],
            rsvp: rsvpStatus,
          },
        };
        return {
          ...initial,
          [`${guestId}_deleted`]: rsvpStatus === false ? true : false,
          [eventKey]: updated,
        };
      }

      return initial;
    }, invitations);

    dispatch({ type: UPDATE_GUEST_RSVP_ALL_EVENTS, payload: updatedResponses });
  };

  const getAcceptedGuests = (eventId: string, getGuestById: IGetGuestById): Person[] => {
    const event = invitations[eventId];

    return Object.keys(event).reduce<Person[]>((initial, guestId) => {
      const hasAccepted: boolean = event[guestId].rsvp === true;
      if (hasAccepted) {
        const guest = getGuestById(guestId);
        if (guest) {
          return [...initial, guest];
        }

        return initial;
      }
      return initial;
    }, []);
  };

  const updateGuestAnswer = (answer: AnswerOption | AnswerText, eventId: string, guestId: string) => {
    const event = invitations[eventId];
    const guest = event ? event[guestId] : null;

    if (!guest) return;

    const updatedResponse = {
      [eventId]: {
        ...event,
      },
    };

    const originalAnswers = updatedResponse[eventId][guestId]?.answers || [];
    const updatedAnswers = originalAnswers.filter(a => a.questionId !== answer.questionId);

    updatedResponse[eventId][guestId].answers = [...updatedAnswers, answer];

    dispatch({ type: UPDATE_GUEST_ANSWER, payload: updatedResponse });
  };

  const allAnswersSelected = (eventId: string, questionId: string): boolean =>
    allAnswerSelectedForEventQuestion(invitations, eventId, questionId);

  return (
    <InvitationContext.Provider
      value={{
        invitations,
        addEvents,
        addGuestsToEvents,
        updateGuestRsvp,
        isGuestResponseValid,
        updateAllRsvpForGuest,
        getAcceptedGuests,
        updateGuestAnswer,
        allAnswerSelectedForEventQuestion: allAnswersSelected,
      }}
    >
      {props.children}
    </InvitationContext.Provider>
  );
}
