import { History, Location } from "history";
import React, { useContext } from "react";
import { match } from "react-router";

import styled from "../../helpers/styled";
import { AnalyticsContext, EventContext, InvitationContext, RouteContext } from "../../Components/Context";
import { FullWidth, MatchParams } from "../../helpers";
import ThemedButton from "../../ThemedButton";
import { EventsList } from "../Events/EventsList";

const Wrapper = styled("div")`
  margin-bottom: 32px;
`;

export interface IEventParams extends MatchParams {
  eventIndex: string;
  questionIndex: string;
}
export interface IGuestResponseProps {
  match: match<IEventParams>;
  history: History;
  location: Location;
  pushRoute: (route: string) => void;
}

export function GuestResponse(props: IGuestResponseProps) {
  const { invitations, updateGuestRsvp, isGuestResponseValid } = useContext(InvitationContext);
  const { events } = useContext(EventContext);
  const { track } = useContext(AnalyticsContext);
  const { pushNextRoute } = React.useContext(RouteContext);

  const {
    match: {
      params: { eventIndex },
    },
  } = props;

  return (
    <FullWidth>
      <Wrapper>
        <EventsList event={events[Number(eventIndex)]} invitations={invitations} updateGuestRsvp={updateGuestRsvp} />
      </Wrapper>
      <ThemedButton
        text="Continue"
        testId="next-event"
        disabled={isGuestResponseValid(events[Number(eventIndex)].id)}
        onClick={() => {
          track({ selection: "complete event", rsvpPage: "event response" });
          pushNextRoute();
        }}
      />
    </FullWidth>
  );
}
