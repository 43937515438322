import Axios, { AxiosResponse } from "axios";

import { IWedding } from "./WeddingContext";

export type weddingDetailsParams = { wedding_slug?: string; domain?: string | string[] };

export interface IWeddingService {
  getWeddingDetails: (...args: [weddingDetailsParams]) => Promise<AxiosResponse<IWedding>>;
}

const { REACT_APP_GUEST_SERVICES_API_URL } = process.env;

const WeddingService: IWeddingService = {
  getWeddingDetails({ wedding_slug, domain }): Promise<AxiosResponse<IWedding>> {
    let params: weddingDetailsParams = {};

    if (wedding_slug) {
      params.wedding_slug = wedding_slug.toLowerCase();
    } else if (domain) {
      params.domain = domain;
    }

    return Axios.get(`${REACT_APP_GUEST_SERVICES_API_URL}/us/api/wedding-details`, {
      params,
    });
  },
};

export default WeddingService;
