import { History } from "history";
import React, { useCallback, useContext } from "react";

import { AnalyticsContext } from "../../Components/Context";
import ThemedButton from "../../ThemedButton";

interface IProps {
  history: History;
  partialMatchCount: number;
}

export function SearchAgainButton({ history, partialMatchCount }: IProps) {
  const { track } = useContext(AnalyticsContext);

  const goBack = useCallback(() => {
    track({
      selection: "search invitations again",
      rsvpPage: "partial match",
      partialMatchCount,
    });
    history.goBack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ThemedButton text="Search Again" onClick={goBack} />;
}
